import React from 'react';
// material
import { Button, Card, CardHeader, CardContent, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2';
// components
import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
// mock
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, workWithLocalStorage, getUniqueKey } from '../../auxiliares/funciones';
import Alta from './alta';
// ----------------------------------------------------------------------

export default function Servicios(props) {
  const [lista, setLista] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());

    const campos = ['categoria','nombre','duracion','precio1','precio2','estado'];
    const cabecera = ['Categoría','Nombre','Duración (min)','Precio 1 (€)','Precio 2 (€)','Estado'];
    const usuario = workWithLocalStorage('get','usuario',null);

    React.useEffect(() => {
      if(!cargado){
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(usuario.token,{}))
        fetch(`${CONFIG.api}servicios/lista`,{
                  method: 'POST',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            if (result.error === 0 && !isEmpty(result.data)) {
              setLista(result.data);
            }
            setKey(getUniqueKey());
            // eslint-disable-next-line react/prop-types
            props.showLoader(false);
            setCargado(true);
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cargado]);

    const altaServicio = (objeto) => {
      if(objeto !== null){
        // eslint-disable-next-line
        props.setTabs({ nombre: `Editar Servicio #${objeto.id}`, componente: Alta, params: {id: objeto.id}, visible: true })
      }else{
        // eslint-disable-next-line
        props.setTabs({ nombre: 'Alta Servicio', componente: Alta, params: {}, visible: true })
      }
    }

    const Actualiza = () =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true);
      // setCargado(false);
      setCargado(false)
    }

    const validaBorrar = (obj) => obj.activo === 1;
    const validaActivar = (obj) => obj.activo !== 1;

    const Activar = (obj) =>{
          // eslint-disable-next-line react/prop-types
          props.showLoader(true);
          const body = {
            tabla: "servicios",
            activo: 1,
            id: obj.id
          }
          const bodyJson = JSON.stringify(buildBody(usuario.token,body))
          fetch(`${CONFIG.api}master/activo`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
              let msg ='Servicios activado!';
              if (result.error === 0) {
                setLista([]);
                setCargado(false);
              }else{
                msg = result.errorMessage;
              }
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
          })
    }

    const Borrar = (obj) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        const body = {
          tabla: "servicios",
          activo: 0,
          id: obj.id
        }
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}master/activo`,{
                  method: 'PUT',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            let msg ='Servicio desactivado!';
              if (result.error === 0) {
                setLista([]);
                setCargado(false);
              }else{
                msg = result.errorMessage;
              }
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
        })
    }

    const theme = useTheme();
    const acciones = [
      {
        color:undefined,
        nombre:'Ver',
        icono:<Iconify icon="eva:edit-fill" style={{ fontSize: '20px' }} />,
        funcion: altaServicio,
        estilo: { backgroundColor: theme.palette.gradients.primary, color: theme.palette.common.white},
        condition: null
      },
      {
        color:'error',
        nombre:'Borrar',
        icono:<i />,
        funcion: Borrar,
        estilo: {},
        condition: validaBorrar
      },
      {
        color:'warning',
        nombre:'Activar',
        icono:<i />,
        funcion: Activar,
        estilo: {},
        condition: validaActivar
      }
    ]

  return (
    <Page title="Servicios" key={lista.length}>
        <Card  sx={{ maxWidth: '100% !important'}}>
          <CardHeader action={<>
                                <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}
                                onClick={()=>altaServicio(null)}>
                                  Alta
                                </Button>
                                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                onClick={()=>Actualiza()}>
                                  Actualizar
                                </Button>
                              </>}
                  title="Servicios"
          />
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <RTabla key={key} lista={lista} campos={campos} cabecera={cabecera} acciones={acciones}/>
          </CardContent>
        </Card>
    </Page>
  );
}
