import React from 'react';
// components
import { Button, Grid } from '@mui/material';
import Swal from 'sweetalert2';

import Iconify from '../../../componentes/Iconify';
import CONFIG from '../../../CONFIG.json';
import { buildBody, isEmpty, workWithLocalStorage, compruebaForm, getUniqueKey } from '../../../auxiliares/funciones';
import {
  getClientes,
  getEstadosConf,
} from '../../../auxiliares/maestros';
import ClienteForm from '../../clientes/form/form';
import RSelect from '../../../componentes/forms/inputs/RSelect';
import RDateField from '../../../componentes/forms/inputs/RDateField';
import RTimeField from '../../../componentes/forms/inputs/RTimeField';
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RLabel from '../../../componentes/forms/inputs/RLabel';
import Recuadro from '../../../componentes/Recuadro';
import { isTimeBetween } from '../../../auxiliares/fechas';

// ----------------------------------------------------------------------
export default function CitaForm(props) {
    const usuario = workWithLocalStorage('get','usuario',null);
    const citaForm = {estadoConf:'',idTrabajador:0,idTrabajador2:0,idCliente:0,idCliente2:0,idBonoCliente:'0',idChequeCliente:0,idServicio:0,idRecepcion:0,idRoom:0,aceite:'',
      acompanamiento:'',fecha:'',hora:'',notas:'',numTicket:'',importe:0,tipoPago:0, realizado:0,codCheque:'',codBono:'',codCheque2:'',codBono2:'',
      importeExtra:0,tipoPagoExtra:0, tiempo:0, tiempoExtra:0, idBonoCliente2:'0',idChequeCliente2:0}
    const clienteForm = {vip:0,nombre:'',apellidos:'',dni:'',telefono:'',email:'',notas:''}
    const [cargado, setCargado] = React.useState(false);
    const [estadosConf, setEstadosConf] = React.useState([]);
    const [clientes, setClientes] = React.useState(props.clientes);
    const [roomsS, setRoomsS] = React.useState([]);
    const [cita, setCita] = React.useState(citaForm);
    const [cliente, setCliente] = React.useState(clienteForm);
    const [bonos, setBonos] = React.useState([]);
    const [cheques, setCheques] = React.useState([]);
    const [muestraBonos, setMuestraBonos] = React.useState(false);
    const [muestraCheques, setMuestraCheques] = React.useState(false);
    const [muestraBonosExtra, setMuestraBonosExtra] = React.useState(false);
    const [muestraChequesExtra, setMuestraChequesExtra] = React.useState(false);
    const [verCliente, setVerCliente] = React.useState(false);
    const [codigoValido, setCodigoValido] = React.useState(false);
    const [codigoValido2, setCodigoValido2] = React.useState(false);
    const [clientKey, setClientKey] = React.useState(0);
    const [trabError, setTrabError] = React.useState('');
    const [trabError2, setTrabError2] = React.useState('');
    const [roomError, setRoomError] = React.useState('');
    const [keyBonoMins, setKeyBonoMins] = React.useState(0);
    const [keyChequeMins, setKeyChequeMins] = React.useState(0);
    const [keyBonoMins2, setKeyBonoMins2] = React.useState(0);
    const [keyChequeMins2, setKeyChequeMins2] = React.useState(0);
    const muestraCliente = () => setVerCliente(true);
    const ocultaCliente = () => {
        setCliente(clienteForm)
        setVerCliente(false);
    }

    const obligatorios = ['idCliente','idServicio','idRecepcion','fecha','hora'];
    const obligatoriosCli = ['nombre','apellidos','telefono'];

    React.useEffect(() => {
        async function cargaInicial(){
            if(!cargado){
                setEstadosConf(getEstadosConf());
                if(props.id !== 0){
                    // eslint-disable-next-line
                    const bodyJson = JSON.stringify(buildBody(usuario.token,{ id:props.id }))
                    fetch(`${CONFIG.api}cita/detalle`,{
                                method: 'POST',
                                body: bodyJson,
                                headers: {'Content-Type': 'application/json'}
                        })
                        .then(res => res.json())
                        .then((result) => {
                        if (result.error === 0 && !isEmpty(result.data)) {
                            setCita(result.data);
                            compruebaRooms(result.data)
                            compruebaBonos(result.data)
                            compruebaCheques(result.data)
                            props.showLoader(false);
                            setCargado(true);
                        }else{
                            props.showLoader(false);
                            setCargado(true);
                        }
                    })
                }else{
                    cita.fecha = props.fecha
                    setCita(cita)
                    compruebaRooms()
                    props.showLoader(false);
                    setCargado(true);
                }
            }
        }
        cargaInicial();
      // eslint-disable-next-line
      },[cargado]);

    const actualizaCampo = (input) =>{
        const nombre = input.target.name
        const valor = input.target.value
        cita[nombre] = valor
        setCita(cita);
        if(nombre === 'idServicio'){
          compruebaRooms()
          compruebaBonos()
          compruebaCheques()
          compruebaPrecio(cita)
        }else if(nombre === 'idCliente' || nombre === 'tipoPago'|| nombre === 'tipoPagoExtra'){
          compruebaBonos()
          compruebaCheques()
          compruebaPrecio(cita)
        }else if(nombre === 'idCliente2'){
          compruebaPrecio(cita)
        }else if(nombre === 'idTrabajador' && cita.hora !== ''){
          comprueboTrabajadorDisp(valor)
        }else if(nombre === 'idTrabajador2' && cita.hora !== ''){
          comprueboTrabajadorDisp2(valor)
        }else if(nombre === 'idRoom' && cita.hora !== ''){
          comprueboRoomDisp(valor)
        }else if(nombre === 'hora'){
          if(cita.idTrabajador !== 0){
            comprueboTrabajadorDisp(cita.idTrabajador)
          }
          if(cita.idTrabajador2 !== 0){
            comprueboTrabajadorDisp2(cita.idTrabajador2)
          }
          if(cita.idRoom !== 0){
            comprueboRoomDisp(cita.idRoom)
          }
        }
    }

    const comprueboTrabajadorDisp = (id) =>{
      let existe = false
      const datos = props.citas.filter(e => e.idTrabajador2 === id || e.idTrabajador === id)
      /* eslint-disable no-restricted-syntax */
      for(const dat of datos){
        if(isTimeBetween(dat.hora,dat.horafin,cita.hora)){
          existe = true
          break
        }
      }
      if(existe){
        setTrabError('Esta ocupada a esa hora')
      }else{
        setTrabError('')
      }
    }

    const comprueboTrabajadorDisp2 = (id) =>{
      let existe = false
      const datos = props.citas.filter(e => e.idTrabajador2 === id || e.idTrabajador === id)
      /* eslint-disable no-restricted-syntax */
      for(const dat of datos){
        if(isTimeBetween(dat.hora,dat.horafin,cita.hora)){
          existe = true
          break
        }
      }
      if(existe){
        setTrabError2('Esta ocupada a esa hora')
      }else{
        setTrabError2('')
      }
    }

    const comprueboRoomDisp = (id) =>{
      let existe = false
      const datos = props.citas.filter(e => e.idRoom === id)
      /* eslint-disable no-restricted-syntax */
      for(const dat of datos){
        if(isTimeBetween(dat.hora,dat.horafin,cita.hora)){
          existe = true
          break
        }
      }
      if(existe){
        setRoomError('Esta ocupada a esa hora')
      }else{
        setRoomError('')
      }
    }

    const compruebaBonos = (citaN) =>{
      const citaC = citaN !== undefined ? citaN : cita;
      if(citaC.tipoPago === 7){
        setMuestraBonos(true)
      }else{
        setMuestraBonos(false)
      }
      if(citaC.tipoPagoExtra === 7){
        setMuestraBonosExtra(true)
      }else{
        setMuestraBonosExtra(false)
      }
      if(citaC.idServicio !== 0 && citaC.idCliente !== 0 && citaC.tipoPago === 7){
        const serv = props.servicios.find(b => b.id === citaC.idServicio);
        if(serv.nombre.startsWith("MASAJE")){
          const usosb = clientes.find(b => b.id === citaC.idCliente).bonos
          const json = JSON.parse(usosb)
          if(!isEmpty(json)) {
            const bons = [{ id: '0', nombre: 'Ninguno' }]
            bons.push(...json)
            setBonos(bons)
          }else{
            citaC.idBonoCliente='0'
            setCita(citaC)
            setBonos([])
          }
        }else{
          citaC.idBonoCliente='0'
          setCita(citaC)
          setBonos([])
        }
      }else{
          citaC.idBonoCliente='0'
          setCita(citaC)
          setBonos([])
      }
      if(citaC.idServicio !== 0 && citaC.idCliente !== 0 && citaC.tipoPagoExtra === 7){
        const serv = props.servicios.find(b => b.id === citaC.idServicio);
        if(serv.nombre.startsWith("MASAJE")){
          const usosb = clientes.find(b => b.id === citaC.idCliente).bonos
          const json = JSON.parse(usosb)
          if(!isEmpty(json)) {
            const bons = [{ id: '0', nombre: 'Ninguno' }]
            bons.push(...json)
            setBonos(bons)
          }else{
            citaC.idBonoCliente2='0'
            setCita(citaC)
            setBonos([])
          }
        }else{
          citaC.idBonoCliente2='0'
          setCita(citaC)
          setBonos([])
        }
      }else{
          citaC.idBonoCliente2='0'
          setCita(citaC)
          setBonos([])
      }
    }

    const compruebaPrecio = (citaN) => {
      if (citaN.idServicio !== 0 && citaN.idCliente !== 0) {
        const serv = props.servicios.find(e => e.id === citaN.idServicio);
        const pers = citaN.idCliente2 !== 0 ? 2 : 1
        let precio = 0
        if (pers === 2 && serv.precio2) {
          precio = serv.precio2
        } else {
          precio = serv.precio1 * pers
        }
        if (!citaN.id) {
          citaN.importe = precio
          setCita(citaN)
        }
      }
    }

    const compruebaCheques = (citaN) =>{
      const citaC = citaN !== undefined ? citaN : cita;
      if(citaC.tipoPago === 9){
        setMuestraCheques(true)
      }else{
        setMuestraCheques(false)
      }
      if(citaC.tipoPagoExtra === 9){
        setMuestraChequesExtra(true)
      }else{
        setMuestraChequesExtra(false)
      }
      if(citaC.idServicio !== 0 && citaC.idCliente !== 0 && citaC.tipoPago === 9){
        const usosb = clientes.find(b => b.id === citaC.idCliente).cheques
        const json = JSON.parse(usosb)
        if(!isEmpty(json)) {
          const bons = [{ id: '0', nombre: 'Ninguno' }]
          bons.push(...json)
          setCheques(bons)
        }else{
          citaC.idChequeCliente='0'
          setCita(citaC)
          setCheques([])
        }
      }else{
          citaC.idChequeCliente='0'
          setCita(citaC)
          setCheques([])
      }
      if(citaC.idServicio !== 0 && citaC.idCliente !== 0 && citaC.tipoPagoExtra === 9){
        const usosb = clientes.find(b => b.id === citaC.idCliente).cheques
        const json = JSON.parse(usosb)
        if(!isEmpty(json)) {
          const bons = [{ id: '0', nombre: 'Ninguno' }]
          bons.push(...json)
          setCheques(bons)
        }else{
          citaC.idChequeCliente2='0'
          setCita(citaC)
          setCheques([])
        }
      }else{
          citaC.idChequeCliente2='0'
          setCita(citaC)
          setCheques([])
      }
    }

    const compruebaRooms = (citaN) =>{
        const citaC = citaN !== undefined ? citaN : cita;
        if(citaC.idServicio !== 0) {
          const roomsServ = props.servicios.find(e => e.id === citaC.idServicio);
          const idsArray = roomsServ.rooms.split(';').map(Number);
          const filteredArray = props.rooms.filter(obj => idsArray.includes(obj.id));
          setRoomsS(filteredArray);
          if (!citaC.id) {
            citaC.importe = roomsServ.precio1
            setCita(citaC)
          }
        }
    }

    const actualizaCampoCli = (input) =>{
        const nombre = input.target.name
        const valor = input.target.value
        cliente[nombre] = valor
        setCliente(cliente);
    }

    const compruebaCodigoExiste = (input) => {
      const nombre = input.target.name
      const valor = input.target.value
      if(valor !== '') {
        const body = {
          tipo: nombre,
          codigo: valor
        }
        const bodyJson = JSON.stringify(buildBody(usuario.token, body))
        fetch(`${CONFIG.api}clientes/check_codigo`, {
          method: 'POST',
          body: bodyJson,
          headers: { 'Content-Type': 'application/json' }
        })
          .then(res => res.json())
          .then((result) => {
            if (result.error !== 0) {
              setCodigoValido(false)
              Swal.fire({
                title: result.errorMessage,
                buttons: [{ label: 'Ok' }]
              })
            }else if(nombre === 'codBono'){
              console.log(result)
              cita.bonoDisponible=result.data
              setKeyBonoMins(result.data)
              setCita(cita)

              if(result.data === 0) {
                Swal.fire({
                  title: 'Este bono no tiene minutos disponibles',
                  buttons: [{ label: 'Ok' }]
                })
              }else{
                Swal.fire({
                  title: `Le quedan ${result.data} minutos disponibles en el bono`,
                  buttons: [{ label: 'Ok' }]
                })
                setCodigoValido(true)
              }
            }else if(nombre === 'codBono2'){
              console.log(result)
              cita.bonoDisponible2=result.data
              setKeyBonoMins2(result.data)
              setCita(cita)

              if(result.data === 0) {
                Swal.fire({
                  title: 'Este bono no tiene minutos disponibles',
                  buttons: [{ label: 'Ok' }]
                })
              }else{
                Swal.fire({
                  title: `Le quedan ${result.data} minutos disponibles en el bono`,
                  buttons: [{ label: 'Ok' }]
                })
                setCodigoValido2(true)
              }
            }else if(nombre === 'codCheque'){
              cita.chequeDisponible=result.data
              setKeyChequeMins(result.data)
              setCita(cita)
              if(result.data !== 0) {
                Swal.fire({
                  title: `Le quedan ${result.data} € disponibles en el cheque`,
                  buttons: [{ label: 'Ok' }]
                })
                setCodigoValido(true)
              }else{
                Swal.fire({
                  title: 'Este cheque ya ha sido usado',
                  buttons: [{ label: 'Ok' }]
                })
              }
            }else if(nombre === 'codCheque2'){
              cita.chequeDisponible2=result.data
              setKeyChequeMins2(result.data)
              setCita(cita)
              if(result.data !== 0) {
                Swal.fire({
                  title: `Le quedan ${result.data} € disponibles en el cheque`,
                  buttons: [{ label: 'Ok' }]
                })
                setCodigoValido2(true)
              }else{
                Swal.fire({
                  title: 'Este cheque ya ha sido usado',
                  buttons: [{ label: 'Ok' }]
                })
              }
            }
          })
      }
    }

    const comprobarBonoCheque = () =>{
      if((Number(cita.idBonoCliente) === 0 && !codigoValido) && cita.tipoPago === 7){
        Swal.fire({
            title: 'No has seleccionado ningun bono correcto.',
            buttons: [{label: 'Ok'}]
        })
        return false
      }
      if((Number(cita.idChequeCliente) === 0 && !codigoValido) && cita.tipoPago === 9){
        Swal.fire({
            title: 'No has seleccionado ningun cheque regalo correcto.',
            buttons: [{label: 'Ok'}]
        })
        return false
      }
      if((Number(cita.idBonoCliente2) === 0 && !codigoValido2) && cita.tipoPagoExtra === 7){
        Swal.fire({
            title: 'No has seleccionado ningun bono correcto.',
            buttons: [{label: 'Ok'}]
        })
        return false
      }
      if((Number(cita.idChequeCliente2) === 0 && !codigoValido2) && cita.tipoPagoExtra === 9){
        Swal.fire({
            title: 'No has seleccionado ningun cheque regalo correcto.',
            buttons: [{label: 'Ok'}]
        })
        return false
      }
      return true
    }

    const Grabar = () =>{
        const valido = compruebaForm(citaForm,cita,obligatorios);
        if(valido){
          if(comprobarBonoCheque()){
            // eslint-disable-next-line react/prop-types
            props.showLoader(true);
            const body = cita;
            const bodyJson = JSON.stringify(buildBody(usuario.token,body))
            fetch(`${CONFIG.api}cita/detalle`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
                let msg ='Cita grabada!';
                if (result.error === 0) {
                    props.Actualiza()
                }else{
                    props.showLoader(false);
                    msg = result.errorMessage;
                }
                Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
                })
            })
          }
        }else{
            Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

    const GrabarCli = () =>{
      const valido = compruebaForm(clienteForm,cliente,obligatoriosCli);
        if(valido){
            // eslint-disable-next-line react/prop-types
            props.showLoader(true);
            const body = cliente;
            const bodyJson = JSON.stringify(buildBody(usuario.token,body))
            fetch(`${CONFIG.api}cliente/detalle`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then(async(result) => {
                let msg ='Cliente grabado!';
                if (result.error === 0) {
                  console.log(result)
                    setClientes(await getClientes(usuario.token));
                    setVerCliente(false);
                    setCliente(clienteForm);
                    cita.idCliente = Number(result.data.id);
                    setCita(cita);
                    setClientKey(getUniqueKey())
                    props.showLoader(false);
                }else{
                    props.showLoader(false);
                    msg = result.errorMessage;
                    props.showLoader(false);
                }
                Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
                })
            })
        }else{
            Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

    const Borrar = () =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        const body = {
          tabla: "cita",
          activo: 0,
          id: props.id
        }
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}master/activo`,{
                  method: 'PUT',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            let msg ='Cita borrada!';
              if (result.error === 0) {
                props.Actualiza()
              }else{
                msg = result.errorMessage;
              }
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
        })
    }

    return (
        <Grid container key={cita.id}>
            {cita.realizado === 0 &&
            <Grid item sm={12} sx={{ textAlign:'right', marginBottom: '30px'}}>
              <Button variant="contained" color="success" sx={{ color: 'white', marginLeft: '15px'}} to="#" onClick={()=>Grabar()}>
                Grabar Cita
              </Button>
              {props.id !== 0 &&
              <Button variant="contained" color="error" sx={{ color: 'white', marginLeft: '15px'}} to="#" onClick={()=>Borrar()}>
                Borrar Cita
              </Button>
              }
            </Grid>
            }
            <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                <RSelect name="estadoConf" label="Confirmación" actualizaCampo={actualizaCampo} value={cita.estadoConf} md={1}
                values={estadosConf}/>
                <RDateField readOnly name="fecha" key={cita.fecha} label="Fecha*" actualizaCampo={actualizaCampo} value={cita.fecha} md={3} />
                <RTimeField name="hora" label="Hora*" actualizaCampo={actualizaCampo} value={cita.hora} md={2} />
                <RSelect name="idRecepcion" label="Recepción*" actualizaCampo={actualizaCampo} value={cita.idRecepcion} md={2}
                values={props.trabajadores.filter(e=> (e.puesto==='RECEPCIONISTA'))}/>
                <RSelect name="idTrabajador" label="Trabajadora" actualizaCampo={actualizaCampo} value={cita.idTrabajador} md={2}
                values={props.trabajadores.filter(e=>(e.puesto==='MASAJISTA' && e.disponibilidad === ''))} errorMsg={trabError}/>
                <RSelect name="idTrabajador2" label="Trabajadora 2" actualizaCampo={actualizaCampo} value={cita.idTrabajador2} md={2}
                values={[{id:0, nombre: ' - '}, ...props.trabajadores.filter(e=>(e.puesto==='MASAJISTA' && e.disponibilidad === ''))]} errorMsg={trabError2}/>
            </Grid>
            <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                <RSelect key={cita.idServicio} name="idServicio" searchable label="Servicio*" actualizaCampo={actualizaCampo} value={cita.idServicio} md={6}
                values={props.servicios}/>
                <RSelect name="idRoom" label="Sala" actualizaCampo={actualizaCampo} value={cita.idRoom} md={2} errorMsg={roomError}
                values={roomsS}/>
                <RTextField type="text" name="acompanamiento" label="Acompañamiento" actualizaCampo={actualizaCampo} value={cita.aceite} md={4} />
            </Grid>
            <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                <RTextField type="text" name="aceite" label="Aceite" actualizaCampo={actualizaCampo} value={cita.aceite} md={3} />
                <RTextField type="text" name="notas" label="Notas" actualizaCampo={actualizaCampo} value={cita.notas} md={9} />
            </Grid>
            <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                <RTextField type="text" name="numTicket" label="Nº Ticket" actualizaCampo={actualizaCampo} value={cita.numTicket} md={2} />
            </Grid>
            <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                { cita.tipoPago !== 7 ?
                <RTextField key={cita.importe} type="number" name="importe" label="Importe" actualizaCampo={actualizaCampo} value={cita.importe} md={2} />
                  :
                <RTextField key={cita.tiempo} type="number" name="tiempo" label="Tiempo" actualizaCampo={actualizaCampo} value={cita.tiempo} md={2} />
                }
                <RSelect name="tipoPago" label="Form. Pago" actualizaCampo={actualizaCampo} value={cita.tipoPago} md={3}
                values={props.tiposPago}/>
                {(!isEmpty(bonos) && muestraBonos) &&
                  <RSelect name="idBonoCliente" label="Usar Bono" actualizaCampo={actualizaCampo} value={cita.idBonoCliente} md={4}
                values={bonos}/>
                }
                {(!isEmpty(cheques) && muestraCheques) &&
                  <RSelect name="idChequeCliente" label="Usar Cheque Regalo" actualizaCampo={actualizaCampo} value={cita.idChequeCliente} md={5}
                values={cheques}/>
                }
                {muestraCheques && <RTextField type="text" name="codCheque" label="Cod. Cheque Reg." onBlurFunc={compruebaCodigoExiste} actualizaCampo={actualizaCampo} value={cita.codCheque} md={3} />}
                {muestraBonos && <RTextField type="text" name="codBono" label="Cod. Bono" onBlurFunc={compruebaCodigoExiste} actualizaCampo={actualizaCampo} value={cita.codBono} md={2} />}
                {(muestraBonos && keyBonoMins !== 0) && <RLabel key={keyBonoMins} label={`${cita.bonoDisponible} mins disp.`} md={1} />}
                {(muestraCheques && keyChequeMins !== 0) && <RLabel key={keyChequeMins} label={`${cita.chequeDisponible} € disp.`} md={3} />}
            </Grid>
            <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                { cita.tipoPagoExtra !== 7 ?
                <RTextField key={cita.importeExtra} type="number" name="importeExtra" label="Importe Extra" actualizaCampo={actualizaCampo} value={cita.importeExtra} md={2} />
                  :
                <RTextField key={cita.tiempoExtra} type="number" name="tiempoExtra" label="Tiempo Extra" actualizaCampo={actualizaCampo} value={cita.tiempoExtra} md={2} />
                }
                <RSelect name="tipoPagoExtra" label="Form. Pago Extra" actualizaCampo={actualizaCampo} value={cita.tipoPagoExtra} md={3}
                values={[{id:0, nombre: ' - '}, ...props.tiposPago]}/>
              {(!isEmpty(bonos) && muestraBonosExtra) &&
                  <RSelect name="idBonoCliente" label="Usar Bono" actualizaCampo={actualizaCampo} value={cita.idBonoCliente} md={4}
                values={bonos}/>
                }
                {(!isEmpty(cheques) && muestraChequesExtra) &&
                  <RSelect name="idChequeCliente" label="Usar Cheque Regalo" actualizaCampo={actualizaCampo} value={cita.idChequeCliente} md={5}
                values={cheques}/>
                }
                {muestraChequesExtra && <RTextField type="text" name="codCheque2" label="Cod. Cheque Reg. Extra" onBlurFunc={compruebaCodigoExiste} actualizaCampo={actualizaCampo} value={cita.codCheque2} md={3} />}
                {muestraBonosExtra && <RTextField type="text" name="codBono2" label="Cod. Bono Extra" onBlurFunc={compruebaCodigoExiste} actualizaCampo={actualizaCampo} value={cita.codBono2} md={2} />}
                {(muestraBonosExtra && keyBonoMins2 !== 0) && <RLabel key={keyBonoMins2} label={`${cita.bonoDisponible2} mins disp.`} md={1} />}
                {(muestraChequesExtra && keyChequeMins2 !== 0) && <RLabel key={keyChequeMins2} label={`${cita.chequeDisponible2} € disp.`} md={3} />}
            </Grid>
            <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                <RSelect name="idCliente" key={clientKey} searchable label="Cliente*" actualizaCampo={actualizaCampo} value={cita.idCliente} md={9}
                values={clientes}/>
              {cita.realizado === 0 &&
                <Grid item md={3} sx={{ textAlign: 'right'}}>
                  <Button variant="contained" color="primary" sx={{ color: 'white', marginLeft: '15px'}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}
                  onClick={()=>muestraCliente()}> Nuevo Cliente </Button>
                </Grid>
              }
            </Grid>
            <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                <RSelect name="idCliente2" searchable label="Cliente 2" actualizaCampo={actualizaCampo} value={cita.idCliente2} md={9}
                values={[{id:0, nombre: ' - '}, ...clientes]}/>
            </Grid>
            {verCliente &&
              <Recuadro titulo={'Datos del cliente'}>
                  <Grid container spacing={1} sx={{ marginBottom: '0px', paddingTop: '20px' }}>
                    <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                        <Grid item sm={12} sx={{textAlign:'right'}}>
                            <Button variant="contained" color="success" sx={{ color: 'white', marginLeft: '15px'}} to="#" onClick={()=>GrabarCli()}>
                              Grabar
                            </Button>
                            <Button variant="contained" color="warning" sx={{ color: 'white', marginLeft: '15px'}} to="#" onClick={()=>ocultaCliente()}>Ocultar</Button>
                        </Grid>
                    </Grid>
                    <ClienteForm cliente={clienteForm} actualizaCampo={actualizaCampoCli} />
                  </Grid>
              </Recuadro>
            }
        </Grid>
    );
}
