import React from 'react';
// components
import { Typography, Paper } from '@mui/material';

// ----------------------------------------------------------------------
export default function Recuadro({ children, titulo, extrasx }) {
    return (
        <Paper variant="outlined" sx={{ padding: 2, position: 'relative', borderRadius: 2,...(extrasx && { ...extrasx }) }}>
            <Typography variant="subtitle1" sx={{ position: 'absolute', top: -14, left: 16, backgroundColor: 'white', paddingLeft: 1, paddingRight: 1 }}>
              {titulo}
            </Typography>
            {children}
        </Paper>
    );
}
