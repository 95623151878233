import React from 'react';
// @mui
import { Grid, Divider } from '@mui/material';
// components
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RCheck from '../../../componentes/forms/inputs/RCheck';
import RTextArea from '../../../componentes/forms/inputs/RTextArea';

// ----------------------------------------------------------------------

export default function ClienteForm({ actualizaCampo, cliente }) {

  return (
    <form style={{ width: '100%'}}>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="nombre" label="Nombre*" actualizaCampo={actualizaCampo} value={cliente.nombre} md={3} />
        <RTextField type="text" name="apellidos" label="Apellidos*" actualizaCampo={actualizaCampo} value={cliente.apellidos} md={4} />
        <RTextField type="text" name="dni" label="DNI" actualizaCampo={actualizaCampo} value={cliente.dni} md={2} />
        <RCheck name="vip" label="VIP" actualizaCampo={actualizaCampo} value={cliente.vip} md={2} />
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="telefono" label="Teléfono*" actualizaCampo={actualizaCampo} value={cliente.telefono} md={2} />
        <RTextField type="text" name="email" label="Email" actualizaCampo={actualizaCampo} value={cliente.email} md={5} />
      </Grid>
      <Grid container spacing={1}>
        <RTextArea name="notas" label="Notas" actualizaCampo={actualizaCampo} value={cliente.notas} md={12} rows={8} />
      </Grid>
    </form>
  );
}
