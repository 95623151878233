import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card, Button, Divider, CardContent, Box, Grid, CardHeader, Modal,
} from '@mui/material';
import Swal from 'sweetalert2';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import MailIcon from '@mui/icons-material/Mail';
import { buildBody, workWithLocalStorage, isEmpty, getUniqueKey } from '../../../auxiliares/funciones';
import CONFIG from '../../../CONFIG.json';
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RSelect from '../../../componentes/forms/inputs/RSelect';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: '750px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export default function BonosClientes(props){
  const usuario = workWithLocalStorage('get','usuario',null);
  const [cheques, setCheques] = React.useState(props.cheques);
  const [servicios] = React.useState(props.servicios);
  const [compra, setCompra] = React.useState({idCliente: props.idCliente, codigo:'', precio: 0, idTipoPago: 0, idServicio:0});
  const [key, setKey] = React.useState(getUniqueKey());

  const ComprarCheque = () =>{
        // eslint-disable-next-line react/prop-types
        if((compra.idServicio !== 0 || compra.precio !== 0) && compra.idTipoPago !== 0) {
          props.showLoader(true);
          const body = compra;
          const bodyJson = JSON.stringify(buildBody(usuario.token, body))
          fetch(`${CONFIG.api}cliente/cheque`, {
            method: 'PUT',
            body: bodyJson,
            headers: { 'Content-Type': 'application/json' }
          })
            .then(res => res.json())
            .then((result) => {
              if (result.error !== 0) {
                Swal.fire({
                  title: result.errorMessage,
                })
              } else {
                const bodyJson = JSON.stringify(buildBody(usuario.token,{ id:props.idCliente }))
                fetch(`${CONFIG.api}cliente/cheques`,{
                            method: 'POST',
                            body: bodyJson,
                            headers: {'Content-Type': 'application/json'}
                    })
                    .then(res => res.json())
                    .then((result) => {
                    if (result.error === 0 && !isEmpty(result.data)) {
                        setCheques(result.data);
                    }
                    props.showLoader(false);
                })
              }
            })
        }else{
          Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

  const actualizaCampo = (input) =>{
      const { name, value } = input.target;
      compra[name] = value
      if(name === 'idServicio'){
        const bo = servicios.find(b => b.id === value)
        console.log(bo)
        compra.precio = bo.precio1
      }
      setCompra(compra);
      if(name === 'idServicio'){
        setKey(getUniqueKey())
      }
  }

  const BorrarCheque = (id) =>{
    // eslint-disable-next-line react/prop-types
    props.showLoader(true);
    const body = {
      tabla: "clientes_cheques",
      activo: 0,
      id
    }
    const bodyJson = JSON.stringify(buildBody(usuario.token,body))
    fetch(`${CONFIG.api}master/activo`,{
              method: 'PUT',
              body: bodyJson,
              headers: {'Content-Type': 'application/json'}
      })
      .then(res => res.json())
      .then((result) => {
        let msg ='Cheque borrado!';
          if (result.error === 0) {
            setCheques(cheques.filter(item => item.id !== id));
            setKey(getUniqueKey());
          }else{
            msg = result.errorMessage;
          }
          Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
          })
          props.showLoader(false);
    })
}

const compruebaCodigoExiste = (input) => {
      const valor = input.target.value
      if(valor !== '') {
        const body = {
          tipo: 'codCheque',
          codigo: valor
        }
        const bodyJson = JSON.stringify(buildBody(usuario.token, body))
        fetch(`${CONFIG.api}clientes/venta_check_codigo`, {
          method: 'POST',
          body: bodyJson,
          headers: { 'Content-Type': 'application/json' }
        })
          .then(res => res.json())
          .then((result) => {
            if(result.data !== ''){
              Swal.fire({
                title: `El código ${valor} ya esta asignado al cliente ${result.data}`,
                buttons: [{ label: 'Ok' }]
              })
              compra.codigo = ''
              setCompra(compra)
              setKey(getUniqueKey())
            }
          })
      }
    }

    const enviarMail = (tipo,id) => {
      props.showLoader(true);
      const body = { tipo, id }
      const bodyJson = JSON.stringify(buildBody(usuario.token, body))
      fetch(`${CONFIG.api}clientes/envio_codigo`, {
        method: 'POST',
        body: bodyJson,
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => res.json())
        .then((result) => {
          let msg = 'Código enviado correctamente'
          if(result.error !== 0){
            msg = result.errorMessage;
          }
          props.showLoader(false);
          Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
          })
        })
    }

  return (
    <Box sx={{ marginTop: '10px'}}>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }} key={key}>
        <RTextField type="text" name="codigo" label="Código" onBlurFunc={compruebaCodigoExiste} actualizaCampo={actualizaCampo} value={compra.codigo} md={2} />
        <RSelect name="idServicio" label="Servicio" position="left" searchable actualizaCampo={actualizaCampo} value={compra.idServicio} md={3}
                values={servicios}/>
        <RTextField type="number" name="precio" label="Precio" actualizaCampo={actualizaCampo} value={compra.precio} md={2} />
        <RSelect name="idTipoPago" label="Forma de Pago*" actualizaCampo={actualizaCampo} value={compra.idTipoPago} md={2}
                values={props.tiposPago.filter(e=> e.id!==7 && e.id!==9 )}/>
        <RSelect name="idTrabajador" label="Recepción*" actualizaCampo={actualizaCampo} value={compra.idTrabajador} md={1}
                values={props.trabajadores.filter(e=> (e.puesto==='RECEPCIONISTA'))}/>
        <Grid item md={1} sx={{ textAlign: 'left'}}>
          <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" onClick={()=>ComprarCheque()}>Comprar</Button>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <TableContainer component={Paper}>
          <Table aria-label="excel-like table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell/>
                <StyledTableCell>Código</StyledTableCell>
                <StyledTableCell>Servicio</StyledTableCell>
                <StyledTableCell>Precio</StyledTableCell>
                <StyledTableCell>F. Pago</StyledTableCell>
                <StyledTableCell>F. Compra</StyledTableCell>
                <StyledTableCell>Recep.</StyledTableCell>
                <StyledTableCell>Estado</StyledTableCell>
                <StyledTableCell>F. Uso</StyledTableCell>
                <StyledTableCell/>
              </StyledTableRow>
            </TableHead>
            <TableBody>
                {cheques.map((t, index)=>
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Button variant="contained" color="primary" sx={{ color: 'white' }} to="#"
                                          onClick={() => enviarMail('Cheque',t.id)} startIcon={<MailIcon />}/>
                    </StyledTableCell>
                    <StyledTableCell>{t.codigo}</StyledTableCell>
                    <StyledTableCell>{t.servicio}</StyledTableCell>
                    <StyledTableCell>{t.precio} €</StyledTableCell>
                    <StyledTableCell>{t.tipoPago}</StyledTableCell>
                    <StyledTableCell>{t.fechaCompra}</StyledTableCell>
                    <StyledTableCell>{t.trabajador}</StyledTableCell>
                    <StyledTableCell>{t.estado}</StyledTableCell>
                    <StyledTableCell>{t.fechaUso}</StyledTableCell>
                    <StyledTableCell>{t.fechaUso === '' &&
                      <Button variant="contained" color="error" sx={{ color: 'white'}} to="#" onClick={()=>BorrarCheque(t.id)}>Borrar</Button>
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
};
