// component
import Iconify from '../../componentes/Iconify';

import { workWithLocalStorage } from '../../auxiliares/funciones';

// eslint-disable-next-line
import Citas from '../../paginas/agenda/citas';
import Clientes from '../../paginas/clientes';
import ClientesAlta from '../../paginas/clientes/alta';
import Trabajadores from '../../paginas/trabajadores';
import TrabajadoresAlta from '../../paginas/trabajadores/alta';
import Horarios from '../../paginas/trabajadores/horarios';
import Servicios from '../../paginas/servicios';
import ServiciosAlta from '../../paginas/servicios/alta';
import Rooms from '../../paginas/rooms';
import RoomsAlta from '../../paginas/rooms/alta';
import Cierres from '../../paginas/cierres';
import CierresAlta from '../../paginas/cierres/alta';
import ListaMes from '../../paginas/listados/mes';
import Ocupacion from '../../paginas/listados/ocupacion';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const user = workWithLocalStorage('get','usuario',null);

const menuConfig = [
  {
    id: 1,
    title: 'Agenda',
    path: '#',
    perfil: [1,2],
    icon: getIcon('eva:people-fill'),
    component: Citas,
    params: {}
  },
  {
    id: 2,
    title: 'Clientes',
    path: '#',
    perfil: [1,2],
    icon: getIcon('eva:people-fill'),
    children: [{
        id: 21,
        title: 'Alta Cliente',
        path: '#',
        perfil: [1,2],
        icon: getIcon('eva:people-fill'),
        component: ClientesAlta,
        params: {}
      },
      {
        id: 22,
        title: 'List. Clientes',
        path: '#',
        perfil: [1,2],
        icon: getIcon('eva:people-fill'),
        component: Clientes,
        params: {}
      }
    ]
  },
  {
    id: 3,
    title: 'Trabajadores',
    path: '#',
    perfil: [1,2],
    icon: getIcon('eva:pie-chart-2-fill'),
    children: [{
        id: 31,
        title: 'Alta Trabajador',
        path: '#',
        perfil: [1,2],
        icon: getIcon('eva:people-fill'),
        component: TrabajadoresAlta,
        params: {}
      },
      {
        id: 32,
        title: 'List. Trabajadores',
        path: '#',
        perfil: [1,2],
        icon: getIcon('eva:people-fill'),
        component: Trabajadores,
        params: {}
      },
      {
        id: 33,
        title: 'Horarios',
        path: '#',
        perfil: [1,2],
        icon: getIcon('eva:people-fill'),
        component: Horarios,
        params: {}
      }
    ]
  },
  {
    id: 4,
    title: 'Servicios',
    path: '#',
    perfil: [1,2],
    icon: getIcon('eva:pie-chart-2-fill'),
    children: [{
        id: 41,
        title: 'Alta Servicio',
        path: '#',
        perfil: [1,2],
        icon: getIcon('eva:people-fill'),
        component: ServiciosAlta,
        params: {}
      },
      {
        id: 42,
        title: 'List. Servicios',
        path: '#',
        perfil: [1,2],
        icon: getIcon('eva:people-fill'),
        component: Servicios,
        params: {}
      }
    ]
  },
  {
    id: 5,
    title: 'Salas',
    path: '#',
    perfil: [1,2],
    icon: getIcon('eva:people-fill'),
    children: [{
        id: 51,
        title: 'Alta Sala',
        path: '#',
        perfil: [1,2],
        icon: getIcon('eva:people-fill'),
        component: RoomsAlta,
        params: {}
      },
      {
        id: 52,
        title: 'List. Salas',
        path: '#',
        perfil: [1,2],
        icon: getIcon('eva:people-fill'),
        component: Rooms,
        params: {}
      }
    ]
  },
  {
    id: 6,
    title: 'Cierres de Caja',
    path: '#',
    perfil: [1,2],
    icon: getIcon('eva:pie-chart-2-fill'),
    children: [{
        id: 61,
        title: 'Cierre del día',
        path: '#',
        perfil: [1,2],
        icon: getIcon('eva:people-fill'),
        component: CierresAlta,
        params: {}
      },
      {
        id: 62,
        title: 'Cierres',
        path: '#',
        perfil: [1,2],
        icon: getIcon('eva:people-fill'),
        component: Cierres,
        params: {}
      }
    ]
  },
  {
    id: 7,
    title: 'Listados',
    path: '#',
    perfil: [1,2],
    icon: getIcon('eva:pie-chart-2-fill'),
    children: [{
        id: 71,
        title: 'Resumen Mes',
        path: '#',
        perfil: [1,2],
        icon: getIcon('eva:people-fill'),
        component: ListaMes,
        params: {}
      },{
        id: 72,
        title: 'Ocupación',
        path: '#',
        perfil: [1,2],
        icon: getIcon('eva:people-fill'),
        component: Ocupacion,
        params: {}
      },
    ]
  }
];

const menuFilter = () => {
  if(user !== undefined){
    // eslint-disable-next-line no-plusplus
    for(let i=menuConfig.length-1; i>=0;i--){
      if(menuConfig[i].children!==undefined){
        // eslint-disable-next-line no-plusplus
        for(let f=menuConfig[i].children.length-1; f>=0;f--){
            if(!menuConfig[i].children[f].perfil.includes(user.idPerfil)){
              menuConfig[i].children.splice(f,1);
            }
        }
      }else if(!menuConfig[i].perfil.includes(user.idPerfil)){
          menuConfig.splice(i,1);
        }
    }
  }
  return menuConfig;
}

const menu  = menuFilter();

export default menu;
