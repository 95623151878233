import React from 'react';
// @mui
import { Grid, Button } from '@mui/material';
import Swal from 'sweetalert2';
// components
import Iconify from '../../../componentes/Iconify';
import RDateField from '../../../componentes/forms/inputs/RDateField';
import RSelect from '../../../componentes/forms/inputs/RSelect';

import { buildBody, isEmpty, getUniqueKey, workWithLocalStorage, compruebaForm } from '../../../auxiliares/funciones';
import CONFIG from '../../../CONFIG.json';
import RTabla from '../../../componentes/tabla';
import { getAusencias } from '../../../auxiliares/maestros';

// ----------------------------------------------------------------------

export default function AusenciaForm({ showLoader, trabajador }) {

  const Form = { inicio:'',fin:'',descripcion:'' };
  const [ausencia, setAusencia] = React.useState(Form);
  const [ausencias, setAusencias] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());
  const usuario = workWithLocalStorage('get','usuario',null);
  const obligatorios = ['inicio','fin','descripcion'];

  React.useEffect(() => {
    async function cargaInicial(){
        if(!cargado){
            setAusencias(await getAusencias(usuario.token,trabajador.id));
            setCargado(true);
            showLoader(false);
        }
    }
    cargaInicial();
  // eslint-disable-next-line
  },[cargado]);

  const actualizaCampo = (input) =>{
    const nombre = input.target.name
    const valor = input.target.value
    ausencia[nombre] = valor
    setAusencia(ausencia);
  }

  const Grabar = () =>{
    const valido = compruebaForm(Form,ausencia,obligatorios);
    if(valido){
        // eslint-disable-next-line react/prop-types
        showLoader(true);
        const body = ausencia;
        body.idTrabajador = trabajador.id;
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}trabajador/ausencia`,{
                method: 'PUT',
                body: bodyJson,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
            let msg ='Ausencia grabada!';
            if (result.error === 0) {
                setCargado(false);
                setKey(getUniqueKey());
            }else{
                msg = result.errorMessage;
            }
            showLoader(false);
            Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
            })
        })
    }else{
        Swal.fire({
            title: 'Revisa los campos con *',
            buttons: [{label: 'Ok'}]
        })
    }
  }

  /* ***************** TABLA ************************** */
  const campos = ['inicio','fin','descripcion'];
  const cabecera = ['Desde','Hasta','Motivo'];

  const BorrarAusencia = (obj) =>{
    // eslint-disable-next-line react/prop-types
    showLoader(true);
    const body = {
      tabla: "trabajador_vacaciones",
      activo: 0,
      id: obj.id
    }
    const bodyJson = JSON.stringify(buildBody(usuario.token,body))
    fetch(`${CONFIG.api}master/activo`,{
              method: 'PUT',
              body: bodyJson,
              headers: {'Content-Type': 'application/json'}
      })
      .then(res => res.json())
      .then((result) => {
        let msg ='Ausencia borrada!';
          if (result.error === 0) {
            setCargado(false);
            setKey(getUniqueKey());
          }else{
            msg = result.errorMessage;
          }
          Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
          })
    })
}

  const acciones = [{
      color:'error',
      nombre:'Borrar',
      icono:<i />,
      funcion: BorrarAusencia,
      estilo: {},
      condition: null
    }]

  return (
    <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <Grid item md={6}>
            <form style={{ width: '100%'}} >
                <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                    <RSelect name="descripcion" label="Motivo*" actualizaCampo={actualizaCampo} value={ausencia.descripcion} md={3}
                    values={[{id:'Vacaciones',nombre:'Vacaciones'},{id:'Baja Médica',nombre:'Baja Médica'}]}/>
                    <RDateField name="inicio" label="Fecha Inicio*" actualizaCampo={actualizaCampo} value={ausencia.inicio} md={3} />
                    <RDateField name="fin" label="Fecha Fin*" actualizaCampo={actualizaCampo} value={ausencia.fin} md={3} />
                    <Grid item md={2}>
                        <Button variant="contained" color="success" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={()=>Grabar()}> Grabar </Button>
                    </Grid>
                </Grid>
            </form>
        </Grid>
        {!isEmpty(ausencias) &&
        <Grid item md={6}>
            <RTabla key={key} lista={ausencias} campos={campos} cabecera={cabecera} acciones={acciones}/>
        </Grid>
        }
    </Grid>
  );
}
