import React from 'react';
import CitasDia from './citas_dia';
import { getUniqueKey, workWithLocalStorage } from '../../auxiliares/funciones';
import { getRooms, getServicios, getTrabajadores, getTiposPago } from '../../auxiliares/maestros';

export default function Citas(props) {
  const usuario = workWithLocalStorage('get','usuario',null);
  const [cargado, setCargado] = React.useState(false);
  const [trabajadores, setTrabajadores] = React.useState([]);
  const [servicios, setServicios] = React.useState([]);
  const [tiposPago, setTiposPago] = React.useState([]);
  const [rooms, setRooms] = React.useState([]);
  const [key, setKey] = React.useState(getUniqueKey);

  React.useEffect(() => {
        async function cargaInicial() {
            if (!cargado) {
                setTrabajadores(await getTrabajadores(usuario.token));
                setServicios(await getServicios(usuario.token));
                setRooms(await getRooms(usuario.token));
                setTiposPago(await getTiposPago(usuario.token));
                props.showLoader(false);
                setKey(getUniqueKey())
                setCargado(true);
            }
        }
        cargaInicial()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cargado]);
  return (<CitasDia key={key} showLoader={props.showLoader} trabajadores={trabajadores} tiposPago={tiposPago} rooms={rooms} servicios={servicios} />)
}