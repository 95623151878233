import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card, Button, Divider, CardContent, Box, Grid,
} from '@mui/material';
import Swal from 'sweetalert2';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { addDaysFromString, formatDateToSpanish, getToday } from '../../auxiliares/fechas';
import Iconify from '../../componentes/Iconify';
import RDateField from '../../componentes/forms/inputs/RDateField';
import { buildBody, getUniqueKey, workWithLocalStorage } from '../../auxiliares/funciones';
import CONFIG from '../../CONFIG.json';
import RSelect from '../../componentes/forms/inputs/RSelect';
import RTimeField from '../../componentes/forms/inputs/RTimeField';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Horarios(props){
  const usuario = workWithLocalStorage('get','usuario',null);
  const [cargado, setCargado] = React.useState(false);
  const [fecha, setFecha] = React.useState(getToday('date'));
  const [trabajadores, setTrabajadores] = React.useState([]);
  const [horarios, setHorarios] = React.useState([]);
  const [trabsKey, setTrabsKey] = React.useState(getUniqueKey());


  React.useEffect(() => {
        async function cargaInicial() {
            if (!cargado) {
                const bodyJson = JSON.stringify(buildBody(usuario.token,{ fecha }))
                fetch(`${CONFIG.api}trabajadores/horarios`,{
                            method: 'POST',
                            body: bodyJson,
                            headers: {'Content-Type': 'application/json'}
                    })
                    .then(res => res.json())
                    .then((result) => {
                      if (result.error === 0) {
                          setTrabajadores(result.data.trabs);
                          console.log(result.data.trabs,result.data.horarios)
                          setHorarios(result.data.horarios);
                      }
                      setTrabsKey(getUniqueKey())
                      props.showLoader(false);
                      setCargado(true);
                })
            }
        }
        cargaInicial()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cargado]);


  const actualizaCampo = (input) =>{
        const nombre = input.target.name
        const valor = input.target.value
        const campo = nombre.split(' ')
        console.log(nombre,campo,valor)
        const index = trabajadores.findIndex(e => e.id === Number(campo[1]))
        trabajadores[index][campo[0]] = valor
        // trabajadores[Number(campo[1])][campo[0]] = valor
        setTrabajadores(trabajadores);
  }

  const Actualiza = () =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true);
      setCargado(false)
  }

  const actualizaFecha = (input) =>{
      if(input.target.value !== '') {
        setFecha(input.target.value);
        Actualiza();
      }
  }

  const modFecha = (cant) =>{
      setFecha(addDaysFromString(fecha,cant));
      Actualiza();
  }

  const GrabarHorarios = () =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        const body = { fecha, trabs: trabajadores };
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}trabajadores/horarios`,{
                method: 'PUT',
                body: bodyJson,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
            let msg ='Horarios grabados!';
            if (result.error !== 0) {
                msg = result.errorMessage;
            }
            Swal.fire({
            title: msg,
            })
            props.showLoader(false);
        })
    }

  return (
    <Box>
        <Card  sx={{ maxWidth: '100% !important'}}>
          <CardContent sx={{ paddingBottom: 0 }}>
            <Grid container>
              <Grid item sm={1} sx={{textAlign:'right'}}>
                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" onClick={()=>modFecha(-1)}>{'<'}</Button>
              </Grid>
              <RDateField key={fecha} name="fecha" label="Fecha" actualizaCampo={actualizaFecha} value={fecha} md={2} />
              <Grid item sm={1} sx={{textAlign:'left'}}>
                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" onClick={()=>modFecha(1)}>{'>'}</Button>
              </Grid>
              <Grid item sm={5}>
                <Typography variant="h4" sx={{textAlign: 'center'}}>Horarios del {formatDateToSpanish(fecha)}</Typography>
              </Grid>
              <Grid item sm={3} sx={{textAlign:'right'}}>
                <Button variant="contained" color="success" sx={{ color: 'white', marginLeft: '15px'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                onClick={()=>Actualiza()}>
                  Actualizar
                </Button>
                <Button variant="contained" color="primary" sx={{ color: 'white', marginLeft: '15px'}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={()=>GrabarHorarios()}>
                  Grabar
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <Grid container>
              <Grid item sm={4}>
                <TableContainer component={Paper}>
                  <Table aria-label="excel-like table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Masajistas</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell/>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody key={trabsKey}>
                        {trabajadores.filter(e=>e.puesto === 'MASAJISTA').map((t, index)=>
                          <StyledTableRow key={index}>
                            <StyledTableCell>{t.nombre}</StyledTableCell>
                            <StyledTableCell>
                              <RSelect name={`idhorario ${t.id}`} label="Horario" actualizaCampo={actualizaCampo} value={t.idhorario} md={12}
                                values={horarios.filter(e=>e.tipo==='MASAJISTA')}/>
                            </StyledTableCell>
                            <StyledTableCell>
                              <RTimeField name={`horaExtra ${t.id}`} label="Hora Extra" actualizaCampo={actualizaCampo} value={t.horaExtra} md={12} />
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item sm={4} sx={{ paddingLeft: '50px'}}>
                <TableContainer component={Paper}>
                  <Table aria-label="excel-like table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Recepcionistas</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell/>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody key={trabsKey}>
                        {trabajadores.filter(e=>e.puesto === 'RECEPCIONISTA').map((t, index)=>
                          <StyledTableRow key={index}>
                            <StyledTableCell>{t.nombre}</StyledTableCell>
                            <StyledTableCell>
                              <RSelect name={`idhorario ${t.id}`} label="Horario" actualizaCampo={actualizaCampo} value={t.idhorario} md={12}
                                values={horarios.filter(e=>e.tipo==='RECEPCIONISTA')}/>
                            </StyledTableCell>
                            <StyledTableCell>
                              <RTimeField name={`horaExtra ${t.id}`} label="Hora Extra" actualizaCampo={actualizaCampo} value={t.horaExtra} md={12} />
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
    </Box>
  );
};
