import CONFIG from '../CONFIG.json';
import { buildBody, isEmpty } from './funciones';

// ----------------------------------------------------------------------

export const getClientes = async(token) => {
    const bodyJson = JSON.stringify(buildBody(token,{}))
    const res = await fetch(`${CONFIG.api}list/clientes`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getTiposPago = async(token) =>{
    const bodyJson = JSON.stringify(buildBody(token,{}))
    const res = await fetch(`${CONFIG.api}list/tipos-pago`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getBonos = async(token) =>{
    const bodyJson = JSON.stringify(buildBody(token,{}))
    const res = await fetch(`${CONFIG.api}list/bonos`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}


export const getEstadosConf = () =>
    [
      {id:'CF',nombre:'CF'},
      {id:'SMS',nombre:'SMS'},
      {id:'JC',nombre:'JC'},
    ];


export const getRooms = async(token) => {
    const bodyJson = JSON.stringify(buildBody(token,{}))
    const res = await fetch(`${CONFIG.api}list/rooms`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getTrabajadores = async(token) => {
    const bodyJson = JSON.stringify(buildBody(token,{}))
    const res = await fetch(`${CONFIG.api}list/trabajadores`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getServicios = async(token) => {
    const bodyJson = JSON.stringify(buildBody(token,{}))
    const res = await fetch(`${CONFIG.api}list/servicios`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getAusencias = async(token, id) => {
    const bodyJson = JSON.stringify(buildBody(token,{id}))
    const res = await fetch(`${CONFIG.api}trabajador/ausencias`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}