import React from 'react';
// @mui
import 'dayjs/locale/es';
import { TextField, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { dateToStringDate, stringDateToDate } from '../../../auxiliares/fechas';

// ----------------------------------------------------------------------


export default function RDateField({ name, value, label, actualizaCampo, md, readOnly = false, readOnlyBtn = false, inputFormat="DD-MM-YYYY", views=['day', 'month', 'year'], ...other }) {
  const [val,setVal] = React.useState(value === '' ? '' : stringDateToDate(value));
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const sm = md * 2;


  const refreshValue = (e) =>{
    if(readOnlyBtn && !isCalendarOpen){
      return
    }
    if (e === null) {
      actualizaCampo({ target: { name, value: '' } });
      setVal('');
    } else {
      setVal(e);
      actualizaCampo({ target: { name, value: dateToStringDate(e.$d) } });
    }
  }

   // Maneja cuando se abre el calendario
  const handleOpen = () => {
    setIsCalendarOpen(true);
  };

  // Maneja las teclas presionadas
  const handleKeyDown = (event) => {
    // Si una tecla se presiona, significa que el usuario está usando el teclado
    if (!isCalendarOpen) {
      console.log('Interacción detectada con el teclado');
    }
  };

  let datePickerComponent;

  if(readOnly) {
    datePickerComponent = (<DesktopDatePicker label={label} inputFormat={inputFormat} views={views} readOnly
                                              onChange={(e) => {
                                                refreshValue(e);
                                              }} value={val} name={name}
                                              renderInput={(params) => <TextField type="number" fullWidth {...params}
                                                                                  error={false}
                                                                                  autoComplete="new-password"
                                                                                  InputLabelProps={{ shrink: true }} {...other} />}
    />);
  } else if(readOnlyBtn) {
    datePickerComponent = (<DesktopDatePicker label={label} inputFormat={inputFormat} views={views}
                                              onChange={(e) => {
                                                refreshValue(e);
                                              }} value={val} name={name} onOpen={handleOpen}
                                              renderInput={(params) => <TextField type="number"
                                                                                  inputProps={{readOnly: true}}
                                                                                  fullWidth {...params} error={false}
                                                                                  autoComplete="new-password"
                                                                                  InputLabelProps={{ shrink: true }} {...other} />}
    />)
  } else {
    datePickerComponent = (<DesktopDatePicker label={label} inputFormat={inputFormat} views={views}
                                              onChange={(e) => {
                                                refreshValue(e);
                                              }} value={val} name={name}
                                              renderInput={(params) => <TextField type="number" fullWidth {...params}
                                                                                  error={false}
                                                                                  autoComplete="new-password"
                                                                                  InputLabelProps={{ shrink: true }} {...other} />}
    />)
  }

  return (
    <Grid item md={md} sm={sm}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
        {datePickerComponent}
      </LocalizationProvider>
    </Grid>
  );
}
