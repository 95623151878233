import React from 'react';
// @mui
import { Box, Grid } from '@mui/material';
import Swal from 'sweetalert2';
// components
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RCheck from '../../../componentes/forms/inputs/RCheck';
import RSelect from '../../../componentes/forms/inputs/RSelect';
import Recuadro from '../../../componentes/Recuadro';
import { buildBody, workWithLocalStorage } from '../../../auxiliares/funciones';
import CONFIG from '../../../CONFIG.json';
import AusenciaForm from './vacaciones';

// ----------------------------------------------------------------------

export default function TrabajadorForm({ actualizaCampo, trabajador, servicios, serviciosT, showLoader }) {
  const usuario = workWithLocalStorage('get','usuario',null);
  const marcoDias = (str) =>{
    const obj = {l:0,m:0,x:0,j:0,v:0,s:0,d:0}
    const dArray = str.split(';');
    // eslint-disable-next-line
    dArray.forEach(day => obj[day.toLowerCase()] = 1);
    return obj
  }

  const marcoServicios = (servsT) =>{
      // eslint-disable-next-line
      servsT.map(s =>{
        servicios.find(e => e.id === s.idServicio).sel = 1;
      })
    return servicios
  }

  const [dias, setDias] = React.useState(marcoDias(trabajador.libranzas));
  const [servs, setServicios] = React.useState(marcoServicios(serviciosT));

  const compruebaDia = (input) =>{
    const nombre = input.target.name
    const valor = input.target.value
    dias[nombre] = valor
    setDias(dias);
    const libranzas = Object.keys(dias).filter(key => dias[key] === 1).join(';').toUpperCase();
    actualizaCampo({target:{value:libranzas,name:'libranzas'}})
  }

  const modServicio = (input) =>{
    const nombre = input.target.name
    const valor = input.target.value
    console.log(nombre,valor)
    servs[Number(nombre)].sel = valor
    setServicios(servs);

    // Llamo por fetch para modificar
    const body = {
      idTrabajador: trabajador.id,
      sel: valor,
      idServicio: servs[Number(nombre)].id
    }
    const bodyJson = JSON.stringify(buildBody(usuario.token,body))
    fetch(`${CONFIG.api}trabajador/servicio`,{
              method: 'PUT',
              body: bodyJson,
              headers: {'Content-Type': 'application/json'}
      })
      .then(res => res.json())
      .then((result) => {
          if (result.error !== 0) {
            Swal.fire({
            title: result.errorMessage,
            buttons: [{label: 'Ok'}]
          })
          }

    })
  }

  return (
    <Box style={{ width: '100%'}}>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="nombre" label="Nombre*" actualizaCampo={actualizaCampo} value={trabajador.nombre} md={3} />
        <RSelect name="puesto" label="Puesto*" actualizaCampo={actualizaCampo} value={trabajador.puesto} md={2}
                values={[{id:'MASAJISTA',nombre:'MASAJISTA'},{id:'RECEPCIONISTA',nombre:'RECEPCIONISTA'}]}/>
        <RSelect name="jornada" label="Jornada" actualizaCampo={actualizaCampo} value={trabajador.jornada} md={2}
                values={[{id:0,nombre:'Media'},{id:1,nombre:'Completa'}]}/>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <Grid item md={12}>
          <Recuadro titulo={'Días que libra'}>
            <Grid container spacing={1}>
              <RCheck name="l" label="Lunes" actualizaCampo={compruebaDia} value={dias.l} md={2} />
              <RCheck name="m" label="Martes" actualizaCampo={compruebaDia} value={dias.m} md={2} />
              <RCheck name="x" label="Miércoles" actualizaCampo={compruebaDia} value={dias.x} md={2} />
              <RCheck name="j" label="Jueves" actualizaCampo={compruebaDia} value={dias.j} md={2} />
              <RCheck name="v" label="Viernes" actualizaCampo={compruebaDia} value={dias.v} md={2} />
              <RCheck name="s" label="Sábado" actualizaCampo={compruebaDia} value={dias.s} md={2} />
              <RCheck name="d" label="Domingo" actualizaCampo={compruebaDia} value={dias.d} md={2} />
            </Grid>
          </Recuadro>
        </Grid>
      </Grid>
      { trabajador.id &&
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <Grid item md={12}>
          <Recuadro titulo={'Servicios que ofrece'}>
            <Grid container spacing={1}>
              {servs.map((serv, index) =>
                  <RCheck key={index} name={index} label={serv.nombre} actualizaCampo={modServicio} value={serv.sel} md={4} />
              )}
            </Grid>
          </Recuadro>
        </Grid>
      </Grid>
      }
      { trabajador.id &&
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Recuadro titulo={'Ausencias'}>
              <AusenciaForm trabajador={trabajador} showLoader={showLoader}/>
            </Recuadro>
        </Grid>
      </Grid>
      }
    </Box>
  );
}
