import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card, Button, Divider, CardContent, Box, Grid, CardHeader, Modal,
} from '@mui/material';
import Swal from 'sweetalert2';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import { styled } from '@mui/material/styles';
import { buildBody, workWithLocalStorage, isEmpty, getUniqueKey, compruebaForm } from '../../../auxiliares/funciones';
import CONFIG from '../../../CONFIG.json';
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RSelect from '../../../componentes/forms/inputs/RSelect';
import RTimeField from '../../../componentes/forms/inputs/RTimeField';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: '750px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export default function Huecos(props){
  const Form = { horaIni: '', horaFin: '', idTrabajador: 0, idRoom: 0}
  const obligatorios = ['horaIni','horaFin','idTrabajador','idRoom'];
  const usuario = workWithLocalStorage('get','usuario',null);
  const [huecos, setHuecos] = React.useState(props.huecos);
  const [hueco, setHueco] = React.useState({ fecha: props.fecha, horaIni: '', horaFin: '', idTrabajador: 0, idRoom: 0});
  const [key, setKey] = React.useState(getUniqueKey());

  const grabarHueco = () =>{
    const valido = compruebaForm(Form,hueco,obligatorios);
        if(valido) {
          // eslint-disable-next-line react/prop-types
          props.showLoader(true);
          // compra.tiempo *= 60
          const body = hueco;
          const bodyJson = JSON.stringify(buildBody(usuario.token, body))
          fetch(`${CONFIG.api}citas/hueco`, {
            method: 'PUT',
            body: bodyJson,
            headers: { 'Content-Type': 'application/json' }
          })
            .then(res => res.json())
            .then((result) => {
              if (result.error !== 0) {
                Swal.fire({
                  title: result.errorMessage,
                })
                props.showLoader(false);
              } else {
                props.Actualiza();
              }
            })
        }else{
          Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

  const actualizaCampo = (input) =>{
      const { name, value } = input.target;
      hueco[name] = value
      setHueco(hueco);
  }

  const BorrarHueco = (id) =>{
    // eslint-disable-next-line react/prop-types
    props.showLoader(true);
    const body = {
      tabla: "citas_huecos",
      activo: 0,
      id
    }
    const bodyJson = JSON.stringify(buildBody(usuario.token,body))
    fetch(`${CONFIG.api}master/activo`,{
              method: 'PUT',
              body: bodyJson,
              headers: {'Content-Type': 'application/json'}
      })
      .then(res => res.json())
      .then((result) => {
          if (result.error === 0) {
            setHuecos(huecos.filter(item => item.id !== id));
            setKey(getUniqueKey());
          }else{
            Swal.fire({
              title: result.errorMessage,
              buttons: [{label: 'Ok'}]
            })
          }
          props.showLoader(false);
    })
}

  return (
    <Box sx={{ marginTop: '10px'}}>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }} key={key}>
        <RTimeField name="horaIni" label="Hora Inicio*" actualizaCampo={actualizaCampo} value={hueco.horaIni} md={2} />
        <RTimeField name="horaFin" label="Hora Fin*" actualizaCampo={actualizaCampo} value={hueco.horaFin} md={2} />
        <RSelect name="idTrabajador" label="Trabajadora" actualizaCampo={actualizaCampo} value={hueco.idTrabajador} md={2}
                values={props.trabajadores.filter(e=>(e.puesto==='MASAJISTA' && e.disponibilidad === ''))} />
        <RSelect name="idRoom" label="Sala*" actualizaCampo={actualizaCampo} value={hueco.idRoom} md={2} values={props.rooms}/>
        <Grid item md={1} sx={{ textAlign: 'left'}}>
          <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" onClick={()=>grabarHueco()}>Grabar</Button>
        </Grid>
      </Grid>
      {huecos.length > 0 &&
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <TableContainer component={Paper}>
          <Table aria-label="excel-like table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Hora Inicio</StyledTableCell>
                <StyledTableCell>Hora Fin</StyledTableCell>
                <StyledTableCell>Trabajador</StyledTableCell>
                <StyledTableCell>Sala</StyledTableCell>
                <StyledTableCell/>
              </StyledTableRow>
            </TableHead>
            <TableBody>
                {huecos.map((t, index)=>
                  <StyledTableRow key={index}>
                    <StyledTableCell>{t.horaIni}</StyledTableCell>
                    <StyledTableCell>{t.horaFin}</StyledTableCell>
                    <StyledTableCell>{t.trabajador}</StyledTableCell>
                    <StyledTableCell>{t.room}</StyledTableCell>
                    <StyledTableCell>
                      <Button variant="contained" color="error" sx={{ color: 'white'}} to="#" onClick={()=>BorrarHueco(t.id)}>Borrar</Button>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      }
    </Box>
  );
};
