import React from 'react';
// @mui
import { Select, MenuItem, Grid, TextField, InputAdornment, ListSubheader, FormHelperText } from '@mui/material';
import { makeStyles } from "@mui/styles";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import SearchIcon from "@mui/icons-material/Search";

import { getUniqueKey } from '../../../auxiliares/funciones';
// ----------------------------------------------------------------------

export default function RSelect({ name, value, label, actualizaCampo, md, values, searchable, position = "center", errorMsg = "", grouping, ...other }) {
    const key = getUniqueKey();
    const [val,setVal] = React.useState(value);
    const sm = md * 2;

    const refreshValue = (e) =>{
        setVal(e.target.value);
    }

    const useStyles = makeStyles(() => ({
        menuPaper: {
          maxHeight: 200
        }
    }));

    const classes = useStyles();

    // INI SEARCHABLE  ---------------------------------------------
    const [searchText, setSearchText] = React.useState("");

    const containsText = (text, searchText) => text.nombre.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    /* const displayedOptions = React.useMemo(
        () => // eslint-disable-next-line
            values.filter((option) => containsText(option, searchText)),[searchText]
    ); */

    // FIN SEARCHABLE  ---------------------------------------------

    const printOptions = (values) => {
        if(grouping){
            const fams = [...new Set(values.map(item => item.familia))];// eslint-disable-next-line
            return fams.map((f,idf) => [<ListSubheader id={idf}>{f}</ListSubheader>,
                    values.map((option,id) => option.familia === f && <MenuItem key={id} value={option.id}>{option.nombre}</MenuItem>)]);
        }else{// eslint-disable-line
            return values.map((c, id)=><MenuItem key={id} value={c.id}>{c.nombre}</MenuItem>)
        }
    }

    return (
        <Grid item md={md} sm={sm}>
            <FormControl fullWidth>
                <InputLabel shrink id={key}>{label}</InputLabel>
                {searchable ?
                <Select labelId={key} name={name} value={val} label={label} onChange={(e)=>{refreshValue(e);actualizaCampo(e);}} onClose={() => setSearchText("")}
                    autoComplete="new-password" {...other} 
                    MenuProps={{ classes: { paper: classes.menuPaper }, autoFocus: false, transformOrigin: { horizontal: position } }}>
                        <ListSubheader>
                            <TextField size="small" autoFocus placeholder="Busca..." fullWidth
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                )
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                // Prevents autoselecting item while typing (default Select behaviour)
                                e.stopPropagation();
                                }
                            }}
                            />
                        </ListSubheader>
                        {printOptions(values.filter((option) => containsText(option, searchText)))}
                </Select>
                :
                <Select labelId={key} name={name} value={val} label={label} onChange={(e)=>{refreshValue(e);actualizaCampo(e);}}
                    autoComplete="new-password" {...other} MenuProps={{ classes: { paper: classes.menuPaper } }}>
                    {printOptions(values)}
                </Select>
                }
                {errorMsg !== "" &&
                <FormHelperText sx={{color: 'red'}}>{errorMsg}</FormHelperText>
                }
            </FormControl>
        </Grid>
    );
}
