import React from 'react';
// @mui
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import { Grid, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// ----------------------------------------------------------------------


export default function RTimeField({ name, value, label, actualizaCampo, md, min, max, ...other }) {
  const [val,setVal] = React.useState(value === '' ? '' : value);
  const sm = md * 2;


  const refreshValue = (e) =>{
      if(e === null){
        actualizaCampo({target:{name,value:''}});
        setVal('');
      }else{
        setVal(e.target.value);
        actualizaCampo({target:{name,value:e.target.value}});
      }
  }

  return (
    <Grid item md={md} sm={sm}>
      <TextField type="time" label={label} value={val} fullWidth error={false} autoComplete="new-password" InputLabelProps={{ shrink: true }}
                 onChange={(e)=>{refreshValue(e);}}/>
    </Grid>
  );
}
