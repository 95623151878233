import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card, CardHeader, Button, Divider, CardContent, Modal, Box, Grid,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import {
  addDaysFromString, addMonthFromString, dateToStringDate,
  formatDateMonthToSpanish,
  getToday,
  monthFromDate,
  yearFromDate,
} from '../../auxiliares/fechas';
import Iconify from '../../componentes/Iconify';
import RDateField from '../../componentes/forms/inputs/RDateField';
import { buildBody, roundHalf, workWithLocalStorage } from '../../auxiliares/funciones';
import CONFIG from '../../CONFIG.json';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CitasDia(props){
  const initFecha = () =>{
    const now = new Date();
    const ahora =  new Date(now.getFullYear(), now.getMonth(), 1);
    return dateToStringDate(ahora)
  }

  const usuario = workWithLocalStorage('get','usuario',null);
  const [cargado, setCargado] = React.useState(false);
  const [lista, setLista] = React.useState([]);
  const [totales, setTotales] = React.useState({ importe: 0});
  const [fecha, setFecha] = React.useState(initFecha());

  const campos = ["fecha","citas","realizados","trabajadores","trabajadoresporc","importes","bonos","info_bonos","cheques","info_cheques","importe_estimado","total_fact","invitaciones"]

  React.useEffect(() => {
        async function cargaInicial() {
            if (!cargado) {
                const bodyJson = JSON.stringify(buildBody(usuario.token,{ mes: monthFromDate(fecha), anyo : yearFromDate(fecha) }))
                fetch(`${CONFIG.api}listados/mes`,{
                            method: 'POST',
                            body: bodyJson,
                            headers: {'Content-Type': 'application/json'}
                    })
                    .then(res => res.json())
                    .then((result) => {
                      if (result.error === 0) {
                        setLista(result.data)
                        calculaTotales(result.data)
                      }
                      props.showLoader(false);
                      setCargado(true);
                })
            }
        }
        cargaInicial()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cargado]);

  const calculaTotales = (data) =>{
    let total = 0
    let totalC = 0
    data.forEach((dataKey) => {
      total += dataKey.total_fact
      totalC += dataKey.importe_estimado
    })
    setTotales({importe: roundHalf(total), importeC: roundHalf(totalC)})
  }

  const Actualiza = () =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true);
      setCargado(false)
  }

  const modFecha = (cant) =>{
      setFecha(addMonthFromString(fecha,cant));
      Actualiza();
  }

  const actualizaFecha = (input) =>{
      if(input.target.value !== '') {
        setFecha(input.target.value);
        Actualiza();
      }
  }

  return (
    <Box>
        <Card  sx={{ maxWidth: '100% !important'}}>
          <CardContent sx={{ paddingBottom: 0 }}>
            <Grid container>
              <Grid item sm={1} sx={{textAlign:'right'}}>
                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" onClick={()=>modFecha(-1)}>{'<'}</Button>
              </Grid>
              <RDateField key={fecha} name="fecha" label="Fecha" actualizaCampo={actualizaFecha} views={['month','year']} inputFormat={"MMMM YYYY"} value={fecha} md={2} />
              <Grid item sm={1} sx={{textAlign:'left'}}>
                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" onClick={()=>modFecha(1)}>{'>'}</Button>
              </Grid>
              <Grid item sm={5}>
                <Typography variant="h4" sx={{textAlign: 'center'}}>Resumen de {formatDateMonthToSpanish(fecha)}</Typography>
              </Grid>
              <Grid item sm={3} sx={{textAlign:'right'}}>
                <Button variant="contained" color="success" sx={{ color: 'white', marginLeft: '15px'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                onClick={()=>Actualiza()}>
                  Actualizar
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <Typography variant="h6" sx={{textAlign: 'left'}}>Total Importe CRM: {totales.importeC} €</Typography>
            <Typography variant="h6" sx={{textAlign: 'left'}}>Total Importe Recepción: {totales.importe} €</Typography>
          </CardContent>
          <CardContent>
            <TableContainer component={Paper}>
              <Table aria-label="excel-like table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell  align="right">Fecha</StyledTableCell >
                    <StyledTableCell  align="right">Nº Citas</StyledTableCell >
                    <StyledTableCell  align="right">Citas Realiz.</StyledTableCell >
                    <StyledTableCell  align="right">Nº Trabajadores</StyledTableCell >
                    <StyledTableCell  align="right">% Ocu. Trabs</StyledTableCell >
                    <StyledTableCell  align="right">Servicios</StyledTableCell >
                    <StyledTableCell  align="right">Bonos</StyledTableCell >
                    <StyledTableCell  align="right">Nº Venta/Usos Bonos</StyledTableCell >
                    <StyledTableCell  align="right">Cheques</StyledTableCell >
                    <StyledTableCell  align="right">Nº Venta/Usos Cheques</StyledTableCell >
                    <StyledTableCell  align="right">Fact. CRM</StyledTableCell >
                    <StyledTableCell  align="right">Fact. Recepción</StyledTableCell >
                    <StyledTableCell  align="right">Nº Invitaciones/Prensa</StyledTableCell >
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                    {lista.map((c, index)=> {
                      return <StyledTableRow key={index} sx={(c.dia_sem === 7 || c.dia_sem === 1) ? {backgroundColor: 'orange !important'} : {}}>
                                {campos.map((campo, index2) =>
                                  <StyledTableCell key={`${campo}${index2}`} align="right">{c[campo]}</StyledTableCell>
                                )}
                              </StyledTableRow>
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
    </Box>
  );
};
