import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card, Button, Divider, CardContent, Box, Grid,
} from '@mui/material';
import Swal from 'sweetalert2';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { addDaysFromString, formatDateToSpanish, getToday } from '../../auxiliares/fechas';
import Iconify from '../../componentes/Iconify';
import RDateField from '../../componentes/forms/inputs/RDateField';
import { buildBody, getUniqueKey, workWithLocalStorage } from '../../auxiliares/funciones';
import CONFIG from '../../CONFIG.json';
import RSelect from '../../componentes/forms/inputs/RSelect';
import RTimeField from '../../componentes/forms/inputs/RTimeField';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Ocupacion(props){
  const usuario = workWithLocalStorage('get','usuario',null);
  const [cargado, setCargado] = React.useState(false);
  const [fecha, setFecha] = React.useState(getToday('date'));
  const [trabajadores, setTrabajadores] = React.useState([]);
  const [trabajadoresOcu, setTrabajadoresOcu] = React.useState(0);


  React.useEffect(() => {
        async function cargaInicial() {
            if (!cargado) {
                const bodyJson = JSON.stringify(buildBody(usuario.token,{ fecha }))
                fetch(`${CONFIG.api}listados/ocupacion`,{
                            method: 'POST',
                            body: bodyJson,
                            headers: {'Content-Type': 'application/json'}
                    })
                    .then(res => res.json())
                    .then((result) => {
                      if (result.error === 0) {
                          setTrabajadores(result.data.trabajadores);
                          setTrabajadoresOcu(result.data.trabajadores[0].media_porc)
                      }else{
                        setTrabajadores([]);
                        setTrabajadoresOcu(0)
                      }
                      props.showLoader(false);
                      setCargado(true);
                })
            }
        }
        cargaInicial()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cargado]);

  const Actualiza = () =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true);
      setCargado(false)
  }

  const actualizaFecha = (input) =>{
      if(input.target.value !== '') {
        setFecha(input.target.value);
        Actualiza();
      }
  }

  const modFecha = (cant) =>{
      setFecha(addDaysFromString(fecha,cant));
      Actualiza();
  }

  return (
    <Box>
        <Card  sx={{ maxWidth: '100% !important'}}>
          <CardContent sx={{ paddingBottom: 0 }}>
            <Grid container>
              <Grid item sm={1} sx={{textAlign:'right'}}>
                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" onClick={()=>modFecha(-1)}>{'<'}</Button>
              </Grid>
              <RDateField key={fecha} name="fecha" label="Fecha" actualizaCampo={actualizaFecha} value={fecha} md={2} />
              <Grid item sm={1} sx={{textAlign:'left'}}>
                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" onClick={()=>modFecha(1)}>{'>'}</Button>
              </Grid>
              <Grid item sm={5}>
                <Typography variant="h4" sx={{textAlign: 'center'}}>Ocupación del {formatDateToSpanish(fecha)}</Typography>
              </Grid>
              <Grid item sm={3} sx={{textAlign:'right'}}>
                <Button variant="contained" color="success" sx={{ color: 'white', marginLeft: '15px'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                onClick={()=>Actualiza()}>
                  Actualizar
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <Grid container>
              <Grid item sm={4}>
                <Typography variant="h6" sx={{textAlign: 'left'}}>Trabajadores: {trabajadoresOcu} %</Typography>
                <TableContainer component={Paper}>
                  <Table aria-label="excel-like table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Trabajador</StyledTableCell>
                        <StyledTableCell>Horas en Uso</StyledTableCell>
                        <StyledTableCell>% Ocupación</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {trabajadores.map((t, index)=>
                          <StyledTableRow key={index}>
                            <StyledTableCell>{t.nombre}</StyledTableCell>
                            <StyledTableCell align="center">{t.horas}</StyledTableCell>
                            <StyledTableCell align="center">{t.porc}</StyledTableCell>
                          </StyledTableRow>
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
    </Box>
  );
};
