import React from 'react';
// @mui
import { TextField, Grid } from '@mui/material';

// ----------------------------------------------------------------------


export default function RTextField({ name, value, label, actualizaCampo, onBlurFunc = null, focusout = false, small = '', type, md, ...other }) {
  const [val,setVal] = React.useState(value);
  const sm = md * 2;


  const refreshValue = (e) =>{
      setVal(e.target.value);
  }

  if(onBlurFunc !== null){
    return (
      <Grid item md={md} sm={sm}>
          <TextField fullWidth type={type} value={val} name={name} label={label} InputLabelProps={{ shrink: true }} 
          onChange={(e)=>{refreshValue(e)}}onBlur={(e)=>{actualizaCampo(e); onBlurFunc(e)}} autoComplete="new-password" {...other} />
          {small !== '' && <small>{small}</small>}
      </Grid>
    );
  }
  if(focusout){
    return (
      <Grid item md={md} sm={sm}>
          <TextField fullWidth type={type} value={val} name={name} label={label} InputLabelProps={{ shrink: true }}
          onChange={(e)=>{refreshValue(e)}}onBlur={(e)=>{actualizaCampo(e)}} autoComplete="new-password" {...other} />
          {small !== '' && <small>{small}</small>}
      </Grid>
    );
  }

    return (
      <Grid item md={md} sm={sm}>
          <TextField fullWidth type={type} value={val} name={name} label={label} InputLabelProps={{ shrink: true }} 
          onChange={(e)=>{refreshValue(e);actualizaCampo(e)}} autoComplete="new-password" {...other} />
          {small !== '' && <small>{small}</small>}
      </Grid>
    );
}
