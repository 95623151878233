import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card, Button, Divider, CardContent, Box, Grid, CardHeader, Modal,
} from '@mui/material';
import Swal from 'sweetalert2';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import { styled } from '@mui/material/styles';
import { buildBody, workWithLocalStorage, isEmpty, getUniqueKey } from '../../../auxiliares/funciones';
import CONFIG from '../../../CONFIG.json';
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RSelect from '../../../componentes/forms/inputs/RSelect';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: '750px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export default function BonosClientes(props){
  const usuario = workWithLocalStorage('get','usuario',null);
  const [bonos, setBonos] = React.useState(props.bonos);
  const [compra, setCompra] = React.useState({idCliente: props.idCliente, codigo:'', tiempo:0, idBono: 0, precio: 0, idTipoPago: 0});
  const [usos, setUsos] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());
  const cierraModal = () => setModalOpen(false);

  const ComprarBono = () =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        if(compra.idBono !== 0 && compra.idTipoPago !== 0) {
          // compra.tiempo *= 60
          const body = compra;
          const bodyJson = JSON.stringify(buildBody(usuario.token, body))
          fetch(`${CONFIG.api}cliente/bono`, {
            method: 'PUT',
            body: bodyJson,
            headers: { 'Content-Type': 'application/json' }
          })
            .then(res => res.json())
            .then((result) => {
              if (result.error !== 0) {
                Swal.fire({
                  title: result.errorMessage,
                })
              } else {
                const bodyJson = JSON.stringify(buildBody(usuario.token,{ id:props.idCliente }))
                fetch(`${CONFIG.api}cliente/bonos`,{
                            method: 'POST',
                            body: bodyJson,
                            headers: {'Content-Type': 'application/json'}
                    })
                    .then(res => res.json())
                    .then((result) => {
                    if (result.error === 0 && !isEmpty(result.data)) {
                        setBonos(result.data);
                    }
                    props.showLoader(false);
                })
              }
            })
        }else{
          Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

  const actualizaCampo = (input) =>{
      const { name, value } = input.target;
      compra[name] = value
      if(name === 'idBono'){
        const bo = props.listaBonos.find(b => b.id === value)
        compra.precio = bo.precio
        compra.tiempo = bo.duracion
      }
      setCompra(compra);
      if(name === 'idBono'){
        setKey(getUniqueKey())
      }
  }

  const UsosBono = (id) =>{
    setModalOpen(true);
    const usosb = bonos.find(b => b.id === id).detalle
    const json = JSON.parse(usosb)
    setUsos(json)
  }

  const BorrarBono = (id) =>{
    // eslint-disable-next-line react/prop-types
    props.showLoader(true);
    const body = {
      tabla: "clientes_bonos",
      activo: 0,
      id
    }
    const bodyJson = JSON.stringify(buildBody(usuario.token,body))
    fetch(`${CONFIG.api}master/activo`,{
              method: 'PUT',
              body: bodyJson,
              headers: {'Content-Type': 'application/json'}
      })
      .then(res => res.json())
      .then((result) => {
        let msg ='Bono borrado!';
          if (result.error === 0) {
            setBonos(bonos.filter(item => item.id !== id));
            setKey(getUniqueKey());
          }else{
            msg = result.errorMessage;
          }
          Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
          })
          props.showLoader(false);
    })
}

  const compruebaCodigoExiste = (input) => {
      const valor = input.target.value
      if(valor !== '') {
        const body = {
          tipo: 'codBono',
          codigo: valor
        }
        const bodyJson = JSON.stringify(buildBody(usuario.token, body))
        fetch(`${CONFIG.api}clientes/venta_check_codigo`, {
          method: 'POST',
          body: bodyJson,
          headers: { 'Content-Type': 'application/json' }
        })
          .then(res => res.json())
          .then((result) => {
            if(result.data !== ''){
              Swal.fire({
                title: `El código ${valor} ya esta asignado al cliente ${result.data}`,
                buttons: [{ label: 'Ok' }]
              })
              compra.codigo = ''
              setCompra(compra)
              setKey(getUniqueKey())
            }
          })
      }
    }

  const enviarMail = (tipo,id) => {
      props.showLoader(true);
      const body = { tipo, id }
      const bodyJson = JSON.stringify(buildBody(usuario.token, body))
      fetch(`${CONFIG.api}clientes/envio_codigo`, {
        method: 'POST',
        body: bodyJson,
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => res.json())
        .then((result) => {
          let msg = 'Código enviado correctamente'
          if(result.error !== 0){
            msg = result.errorMessage;
          }
          props.showLoader(false);
          Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
          })
        })
    }

  return (
    <Box sx={{ marginTop: '10px'}}>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }} key={key}>
        <RTextField type="text" name="codigo" label="Código" onBlurFunc={compruebaCodigoExiste} actualizaCampo={actualizaCampo} value={compra.codigo} md={2} />
        <RSelect name="idBono" label="Bono*" actualizaCampo={actualizaCampo} value={compra.idBono} md={2}
                values={props.listaBonos}/>
        <RTextField type="number" name="precio" label="Precio*" actualizaCampo={actualizaCampo} value={compra.precio} md={1} />
        <RTextField type="number" name="tiempo" label="Tiempo*" actualizaCampo={actualizaCampo} value={compra.tiempo/60} md={2} />
        <RSelect name="idTipoPago" label="Forma de Pago*" actualizaCampo={actualizaCampo} value={compra.idTipoPago} md={2}
                values={props.tiposPago}/>
        <RSelect name="idTrabajador" label="Recepción*" actualizaCampo={actualizaCampo} value={compra.idTrabajador} md={1}
                values={props.trabajadores.filter(e=> (e.puesto==='RECEPCIONISTA'))}/>
        <Grid item md={1} sx={{ textAlign: 'left'}}>
          <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" onClick={()=>ComprarBono()}>Comprar</Button>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <TableContainer component={Paper}>
          <Table aria-label="excel-like table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell/>
                <StyledTableCell>Código</StyledTableCell>
                <StyledTableCell>Tipo</StyledTableCell>
                <StyledTableCell>Horas</StyledTableCell>
                <StyledTableCell>Precio</StyledTableCell>
                <StyledTableCell>Precio Orig.</StyledTableCell>
                <StyledTableCell>F. Pago</StyledTableCell>
                <StyledTableCell>Horas Disp.</StyledTableCell>
                <StyledTableCell>F. Compra</StyledTableCell>
                <StyledTableCell>Recep.</StyledTableCell>
                <StyledTableCell>Estado</StyledTableCell>
                <StyledTableCell/>
              </StyledTableRow>
            </TableHead>
            <TableBody>
                {bonos.map((t, index)=>
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Button variant="contained" color="primary" sx={{ color: 'white' }} to="#"
                                          onClick={() => enviarMail('Bono',t.id)} startIcon={<MailIcon />}/>
                    </StyledTableCell>
                    <StyledTableCell>{t.codigo}</StyledTableCell>
                    <StyledTableCell>{t.tipo}</StyledTableCell>
                    <StyledTableCell>{t.horas}</StyledTableCell>
                    <StyledTableCell>{t.precio}</StyledTableCell>
                    <StyledTableCell>{t.precioO}</StyledTableCell>
                    <StyledTableCell>{t.tipoPago}</StyledTableCell>
                    <StyledTableCell>{(t.total-t.consumido)/60} h</StyledTableCell>
                    <StyledTableCell>{t.fechaCompra}</StyledTableCell>
                    <StyledTableCell>{t.trabajador}</StyledTableCell>
                    <StyledTableCell>{t.activo === 1 ? 'Activo' : 'Consumido'}</StyledTableCell>
                    <StyledTableCell>{t.consumido === 0 ?
                      <Button variant="contained" color="error" sx={{ color: 'white'}} to="#" onClick={()=>BorrarBono(t.id)}>Borrar</Button>
                      :
                      <Button variant="contained" color="primary" sx={{ color: 'white'}} to="#" onClick={()=>UsosBono(t.id)}>ver Usos</Button>}
                    </StyledTableCell>
                  </StyledTableRow>
                )}
            </TableBody>
          </Table>
          <Modal open={modalOpen} onClose={cierraModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Card sx={style}>
                  <CardHeader title="Usos del bono"
                  action={<>
                      <CloseIcon fontSize="inherit" onClick={() => cierraModal() }/>
                  </>}/>
                  <Divider style={{ margin: '15px'}} />
                  <CardContent sx={{ maxHeight: '600px', overflowY: 'auto' }}>
                    { !isEmpty(usos) &&
                    <TableContainer component={Paper} sx={{ maxHeight: '350px', overflowY: 'auto'}}>
                      <Table aria-label="excel-like table">
                        <TableHead>
                          <StyledTableRow>
                            <StyledTableCell>Fecha</StyledTableCell >
                            <StyledTableCell>Servicio</StyledTableCell >
                            <StyledTableCell>Duracion</StyledTableCell >
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {usos.map((t, index)=>
                              <StyledTableRow key={index}>
                                <StyledTableCell>{t.fecha}</StyledTableCell>
                                <StyledTableCell>{t.servicio}</StyledTableCell>
                                <StyledTableCell>{t.duracion}</StyledTableCell>
                              </StyledTableRow>
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    }
                  </CardContent>
              </Card>
          </Modal>
        </TableContainer>
      </Grid>
    </Box>
  );
};
