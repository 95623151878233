import React from 'react';
// material
import { Button, Card, CardHeader, CardContent, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
// mock
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, workWithLocalStorage, getUniqueKey } from '../../auxiliares/funciones';
import Alta from './alta';
// ----------------------------------------------------------------------

export default function Cierres(props) {
  const [lista, setLista] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());

    const campos = ['fecha','debe','hay','notas','usuario','alta'];
    const cabecera = ['Fecha','Debe Haber','Hay','Notas','Usuario','Alta'];
    const usuario = workWithLocalStorage('get','usuario',null);

    React.useEffect(() => {
      if(!cargado){
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(usuario.token,{}))
        fetch(`${CONFIG.api}lista/cierres`,{
                  method: 'POST',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            if (result.error === 0 && !isEmpty(result.data)) {
              setLista(result.data);
            }else{
              setLista([])
            }
            setKey(getUniqueKey());
            // eslint-disable-next-line react/prop-types
            props.showLoader(false);
            setCargado(true);
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cargado]);

    const altaCierre = (objeto) => {
      if(objeto !== null){
        // eslint-disable-next-line
        props.setTabs({ nombre: `Editar Cierre #${objeto.id}`, componente: Alta, params: {fecha: objeto.fechaF}, visible: true })
      }else{
        // eslint-disable-next-line
        props.setTabs({ nombre: 'Alta Cierre', componente: Alta, params: {}, visible: true })
      }
    }

    const Actualiza = () =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true);
      // setCargado(false);
      setCargado(false)
    }

    const theme = useTheme();
    const acciones = [
      {
        color:undefined,
        nombre:'Ver',
        icono:<Iconify icon="eva:edit-fill" style={{ fontSize: '20px' }} />,
        funcion: altaCierre,
        estilo: { backgroundColor: theme.palette.gradients.primary, color: theme.palette.common.white},
        condition: null
      }
    ]

  return (
    <Page title="Cierres" key={lista.length}>
        <Card  sx={{ maxWidth: '100% !important'}}>
          <CardHeader action={<>
                                <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}
                                onClick={()=>altaCierre(null)}>
                                  Alta
                                </Button>
                                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                onClick={()=>Actualiza()}>
                                  Actualizar
                                </Button>
                              </>}
                  title="Cierres"
          />
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <RTabla key={key} lista={lista} campos={campos} cabecera={cabecera} acciones={acciones}/>
          </CardContent>
        </Card>
    </Page>
  );
}
