import React from 'react';
// @mui
import { TextField, Grid } from '@mui/material';

// ----------------------------------------------------------------------


export default function RTextField({ name, value, label, actualizaCampo, md, rows = 4, ...other }) {
  const [val,setVal] = React.useState(value);
  const sm = md * 2;


  const refreshValue = (e) =>{
      setVal(e.target.value);
  }

  return (
    <Grid item md={md} sm={sm}>
        <TextField multiline fullWidth type="text" rows={rows} value={val} name={name} label={label} InputLabelProps={{ shrink: true }}
        onChange={(e)=>{refreshValue(e);actualizaCampo(e)}} autoComplete="new-password" {...other} />
    </Grid>
  );
}
