import React from 'react';
// @mui
import { Grid } from '@mui/material';
import Swal from 'sweetalert2';
// components
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RCheck from '../../../componentes/forms/inputs/RCheck';
import RSelect from '../../../componentes/forms/inputs/RSelect';
import Recuadro from '../../../componentes/Recuadro';
import { buildBody, workWithLocalStorage } from '../../../auxiliares/funciones';
import CONFIG from '../../../CONFIG.json';

// ----------------------------------------------------------------------

export default function ServicioForm({ actualizaCampo, rooms, servicio }) {
  const marcoRooms = (str) =>{
      if(str!=='') {
        // eslint-disable-next-line
        str.split(';').map(s => {
          rooms.find(e => e.id === Number(s)).sel = 1;
        })
      }
      return rooms
  }

  const [salas, setSalas] = React.useState(marcoRooms(servicio.rooms));

  const compruebaSala = (input) =>{
    const index = Number(input.target.name)
    const valor = input.target.value
    salas[index].sel = valor
    setSalas(salas);
    const salasS = salas.filter(sala => sala.sel === 1).map(item => item.id) .join(';').toUpperCase();
    actualizaCampo({target:{value:salasS,name:'rooms'}})
  }

  return (
    <form style={{ width: '100%'}}>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RSelect name="categoria" label="Categoría*" actualizaCampo={actualizaCampo} value={servicio.categoria} md={3}
                values={[{id:'MASAJES',nombre:'MASAJES'},{id:'TRATAMIENTO CORPORAL',nombre:'TRATAMIENTO CORPORAL'},{id:'RITUALES',nombre:'RITUALES'}]}/>
        <RTextField type="text" name="nombre" label="Nombre*" actualizaCampo={actualizaCampo} value={servicio.nombre} md={3} />
        <RTextField type="number" name="duracion" label="Duración(min)*" actualizaCampo={actualizaCampo} value={servicio.duracion} md={2} />
        <RTextField type="number" name="precio1" label="Precio 1*" actualizaCampo={actualizaCampo} value={servicio.precio1} md={2} />
        <RTextField type="number" name="precio2" label="Precio 2*" actualizaCampo={actualizaCampo} value={servicio.precio2} md={2} />
      </Grid>
      <Grid container spacing={1}>
        <Grid item md={12}>
          <Recuadro titulo={'Salas donde se ofrece'}>
            <Grid container spacing={1}>
              {rooms.map((room, index) =>
                  <RCheck key={index} name={index} label={room.nombre} actualizaCampo={compruebaSala} value={room.sel} md={3} />
              )}
            </Grid>
          </Recuadro>
        </Grid>
      </Grid>
    </form>
  );
}
