import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card, Button, Divider, CardContent, Box, Grid, CardHeader, Modal,
} from '@mui/material';
import Swal from 'sweetalert2';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import { styled } from '@mui/material/styles';
import { buildBody, workWithLocalStorage, isEmpty, getUniqueKey, compruebaForm } from '../../../auxiliares/funciones';
import CONFIG from '../../../CONFIG.json';
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RSelect from '../../../componentes/forms/inputs/RSelect';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: '750px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export default function ProductosClientes(props){
  const Form = { descripcion: '', unidades: 0, precio: 0, idTipoPago: 0, idTrabajador: 0}
  const obligatorios = ['descripcion','unidades','precio','idTipoPago', 'idTrabajador'];
  const usuario = workWithLocalStorage('get','usuario',null);
  const [productos, setProductos] = React.useState(props.productos);
  const [compra, setCompra] = React.useState({idCliente: props.idCliente, descripcion:'', unidades: 0, precio: 0, idTipoPago: 0, idTrabajador:0});
  const [key, setKey] = React.useState(getUniqueKey());

  const ComprarProducto = () =>{
    const valido = compruebaForm(Form,compra,obligatorios);
        if(valido) {
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
          const body = compra;
          const bodyJson = JSON.stringify(buildBody(usuario.token, body))
          fetch(`${CONFIG.api}cliente/producto`, {
            method: 'PUT',
            body: bodyJson,
            headers: { 'Content-Type': 'application/json' }
          })
            .then(res => res.json())
            .then((result) => {
              if (result.error !== 0) {
                Swal.fire({
                  title: result.errorMessage,
                })
              } else {
                const bodyJson = JSON.stringify(buildBody(usuario.token,{ id:props.idCliente }))
                fetch(`${CONFIG.api}cliente/productos`,{
                            method: 'POST',
                            body: bodyJson,
                            headers: {'Content-Type': 'application/json'}
                    })
                    .then(res => res.json())
                    .then((result) => {
                    if (result.error === 0 && !isEmpty(result.data)) {
                        setProductos(result.data);
                    }
                    props.showLoader(false);
                })
              }
            })
        }else{
          Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

  const actualizaCampo = (input) =>{
      const { name, value } = input.target;
      compra[name] = value
      setCompra(compra);
  }

  const BorrarProducto = (id) =>{
    // eslint-disable-next-line react/prop-types
    props.showLoader(true);
    const body = {
      tabla: "clientes_productos",
      activo: 0,
      id
    }
    const bodyJson = JSON.stringify(buildBody(usuario.token,body))
    fetch(`${CONFIG.api}master/activo`,{
              method: 'PUT',
              body: bodyJson,
              headers: {'Content-Type': 'application/json'}
      })
      .then(res => res.json())
      .then((result) => {
        if (result.error === 0) {
          setProductos(productos.filter(item => item.id !== id));
          setKey(getUniqueKey());
        }else{
          Swal.fire({
            title: result.errorMessage,
            buttons: [{label: 'Ok'}]
          })
        }
        props.showLoader(false);
    })
}

  return (
    <Box sx={{ marginTop: '10px'}}>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }} key={key}>
        <RTextField type="text" name="descripcion" label="Producto*" actualizaCampo={actualizaCampo} value={compra.descripcion} md={3} />
        <RTextField type="number" name="unidades" label="Unidades*" actualizaCampo={actualizaCampo} value={compra.unidades} md={1} />
        <RTextField type="number" name="precio" label="Precio Final*" actualizaCampo={actualizaCampo} value={compra.precio} md={1} />
        <RSelect name="idTipoPago" label="Forma de Pago*" actualizaCampo={actualizaCampo} value={compra.idTipoPago} md={2}
                values={props.tiposPago}/>
        <RSelect name="idTrabajador" label="Recepción*" actualizaCampo={actualizaCampo} value={compra.idTrabajador} md={1}
                values={props.trabajadores.filter(e=> (e.puesto==='RECEPCIONISTA'))}/>
        <Grid item md={1} sx={{ textAlign: 'left'}}>
          <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" onClick={()=>ComprarProducto()}>Comprar</Button>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <TableContainer component={Paper}>
          <Table aria-label="excel-like table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Producto</StyledTableCell>
                <StyledTableCell>Unidades</StyledTableCell>
                <StyledTableCell>Precio</StyledTableCell>
                <StyledTableCell>Trabajador</StyledTableCell>
                <StyledTableCell>Form. Pago</StyledTableCell>
                <StyledTableCell>F. Compra</StyledTableCell>
                <StyledTableCell/>
              </StyledTableRow>
            </TableHead>
            <TableBody>
                {productos.map((t, index)=>
                  <StyledTableRow key={index}>
                    <StyledTableCell>{t.descripcion}</StyledTableCell>
                    <StyledTableCell>{t.unidades}</StyledTableCell>
                    <StyledTableCell>{t.precio}</StyledTableCell>
                    <StyledTableCell>{t.trabajador}</StyledTableCell>
                    <StyledTableCell>{t.tipoPago}</StyledTableCell>
                    <StyledTableCell>{t.fechaCompra}</StyledTableCell>
                    <StyledTableCell>
                      <Button variant="contained" color="error" sx={{ color: 'white'}} to="#" onClick={()=>BorrarProducto(t.id)}>Borrar</Button>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
};
