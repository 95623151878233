import React from 'react';
// @mui
import { Grid } from '@mui/material';
// components
import RTextField from '../../../componentes/forms/inputs/RTextField';
import RCheck from '../../../componentes/forms/inputs/RCheck';
import RTextArea from '../../../componentes/forms/inputs/RTextArea';

// ----------------------------------------------------------------------

export default function SalaForm({ actualizaCampo, sala }) {

  return (
    <form style={{ width: '100%'}}>
      <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <RTextField type="text" name="nombre" label="Nombre*" actualizaCampo={actualizaCampo} value={sala.nombre} md={3} />
        <RTextField type="text" name="codigo" label="Código*" actualizaCampo={actualizaCampo} value={sala.codigo} md={2} />
        <RTextField type="number" name="max" label="Máx. Pers.*" actualizaCampo={actualizaCampo} value={sala.max} md={2} />
      </Grid>
    </form>
  );
}
